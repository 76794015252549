<template>
  <transition name="fade" appear mode="out-in">
    <div
      v-if="progress < 100"
      class="loader-container justify-center flex items-center z-30"
    >
      <div class="block">
         <img
            src="img/logo.png"
            class=" mx-auto sm:max-w-md max-w-full px-12"
          />
          <!-- <h1
            class="
              lg:text-xl
              text-sm
              text-white
              font-display
              mb-12
              mt-8
              text-center
            "
          >
            Swap
          </h1> -->
        <h1
          class="
            text-center
            mt-8
            font-display
            text-5xl
            sm:text-7xl
          "
        >
          {{ progress }}%
        </h1>
      </div>
    </div>
    <div v-else-if="progress === 100" class="h-full relative z-40 flex  mx-auto max-w-xl xl:mx-0 sm:max-w-7xl ">
        <slot />
    </div>
  </transition>
  <!-- <NoMetaMask v-if="noMetaMask"/> -->
</template>

<script>
import NoMetaMask from "./NoMetaMask";
export default {
  name: "PageLoading",
  data() {
    return {
      progress: 0,
      interval: null,
      noMetaMask: false,
    };
  },
  components: {
    NoMetaMask,
  },
  computed: {
    ready() {
      return this.$store.state.pageReady;
    },
  },
  async mounted() {
    this.interval = window.setInterval(() => {
      if (this.progress >= 95) {
        // When progress completes, we have to clear the interval
        window.clearInterval(this.interval);
      } else {
        this.progress = this.progress + 2;
      }
    }, 150);

    try {
      if (typeof window.ethereum !== "undefined") {
        window.ethereum.autoRefreshOnNetworkChange = false;
        this.$store.dispatch("loadWeb3");

        /* Case 2 - User switch account */
        window.ethereum.on("accountsChanged", async () => {
          this.$store.dispatch("loadWeb3");
        });
        /* Case 3 - User switch network */
        window.ethereum.on("chainChanged", async () => {
          this.$store.dispatch("loadWeb3");
        });
      } else {
        this.$store.commit("setPageReady");
      }
    } catch (e) {
      console.error("Error, load Web3: ", e);
    }
  },
  watch: {
    ready(newVal) {
      if (newVal) {
        setTimeout(() => {
        this.progress = 100;  
        }, 1000);
        
      }
    },
  },
};
</script>

<style scoped>
.loader-container {
  min-width: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
.bouncing-logo-loader {
  width: 350px;
}
h1 {
  color: white;
  letter-spacing: 1.2px;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
