import { createApp } from 'vue'
import App from './App.vue'
import './styles/app.css';
import store from './store';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
require('./icons.js');
import 'tippy.js/dist/tippy.css'
import VueTippy from 'vue-tippy'
import 'tippy.js/dist/svg-arrow.css'
import Vue3Transitions from 'vue3-transitions'
const app = createApp(App);
app.use(store);
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(Vue3Transitions)
app.use(
    VueTippy,
    // optional
    {
      directive: 'tippy', // => v-tippy
      component: 'tippy', // => <tippy/>
      componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
      defaultProps: {
        placement: 'auto-end',
        allowHTML: true,
      }, // => Global default options * see all props
    }
  )
  

app.mount('#app');
