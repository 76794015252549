
import keyBy from 'lodash/keyBy';
import reduce from 'lodash/reduce';  
import { ETH_COIN_ADDRESS } from '../utils/ethereumFunctions';

export default {
    state: {
        loading: false,
        tokens: {},
        gotTokens: false,
        error: false
    },
    getters: {
        tokenBalance: (state, getters, rootState) => address => {
            if(address===ETH_COIN_ADDRESS){
                return (rootState.eth.eth_balance) ?rootState.eth.eth_balance:0
            }
            if (! state.gotTokens || state.error) return 0;
            return state.tokens[address] ?? 0;
        },
        walletTokenList(state){
            let i = 0;
        return reduce(state.tokens, (result, balance, address)=>{
                result.push(   {
                    ca: address,
                    order: i,
                    type: 'draggable'
                },);
                i++;
                return result
            }, []);
        },
  
    },
    mutations: {
        setLoading: (state, val) => state.loading = val,
        setTokens: (state, tokens) => state.tokens = tokens,
        setGotTokens: (state, val) => state.gotTokens = val,
        setError: (state, val) => state.error = val,
        clearWallet(state){
            state.tokens = {};
            state.gotTokens = false;
        }
    },
    actions: {
        async getWalletTokens({
            state,
            commit,
            rootState,
            getters,
            dispatch
        }, address) {
            if (!state.loading && !state.error && !state.gotTokens) {
                commit('setLoading', true);
                const { EvmChain } = require("@moralisweb3/common-evm-utils");
                const Moralis = require("moralis").default;

                await Moralis.start({
                    apiKey: "MQKPyIM2245DRYtVHT0QHOkn3msd4gNCDI9hCT5nmgdyVbJNFzEt1VlN5sKz00rS",
                });
                  
                const _address =   (address) ? address : rootState.eth.connected_address;
                const chain =  EvmChain.ETHEREUM;

                const req = await Moralis.EvmApi.token.getWalletTokenBalances({
                    address: _address,
                    chain,
                });
                const tokensInWallet = req.jsonResponse;
                console.log(tokensInWallet)
                let validTokens = [];
                for(let i=0;i<tokensInWallet.length;i++){
                    if(getters.tokenByCa( tokensInWallet[i].token_address)){
                        validTokens.push(tokensInWallet[i].token_address);
                    }
                    else {
                        const check = await dispatch('getTokenByCa',tokensInWallet[i].token_address );
                        console.log(check)
                        if(check){
                            validTokens.push(tokensInWallet[i].token_address)
                        }
                    }
                }

                const Moralisv1 = require("moralis-v1");
                const tokens = reduce(tokensInWallet, (result, item, key) => {
                    const b = Moralisv1.Units.FromWei(item.balance, item.decimals).toString();
                    result[item.token_address.toString()] = b;
                    return result;
                }, {})


                tokens[ETH_COIN_ADDRESS] = rootState.native_balance;
                commit('setTokens', tokens);
                commit('setLoading', false);
                commit('setGotTokens', true);
                commit('addCards', {
                    list: 'wallet',
                    cards: reduce(validTokens, (result, token, index)=>{
                        result.push({
                            ca: token,
                            order: {
                                wallet: index  +1
                            },
                            type: "draggable"
                        });
                        return result;
                    }, [])
                })
            }
        }
    }
}