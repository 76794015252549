<template>
  <p class="uppercase text-sm tracking-wide font-medium opacity-70">
    {{ (isExactOutput) ? 'Expected Tokens In' : 'Tokens In' }}
  </p>
  <p class="mb-4 text-lg font-medium uppercase">
    {{ $store.getters.minAmountIn }} {{ token_in.symbol }}
  </p>
  <p
    v-if="isExactOutput"
    class="uppercase text-sm tracking-wide font-medium opacity-70"
  >
    Max Tokens In
  </p>
  <p v-if="isExactOutput" class="mb-4 text-lg font-medium uppercase">
    {{ $store.getters.maxAmountIn }} {{ token_in.symbol }}
  </p>
</template>

<script>
export default {
  name: "SwapConfirmTokensIn",
  computed: {
    isExactOutput() {
      return this.$store.state.swap.trade_type === "EXACT_OUTPUT";
    },
    token_in() {
      return this.$store.getters.tokenByCa(
        this.$store.state.swap.token_in_address
      );
    },
  },
};
</script>

<style scoped></style>
