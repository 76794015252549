<template>
  <div class="mx-auto flex justify-center ">
    <primary-button
      v-if="!$store.state.eth.connected_address"
        @click="$store.dispatch('requestConnectAccount')"
    >
      Connect Wallet
    </primary-button>
    <primary-button
      v-else-if="$store.state.eth.connected_network !== chainId"
      @click="$store.dispatch('requestNetworkChange')"
    >
      Switch Network
    </primary-button>
    <slot v-else/>
  </div>
</template>

<script>
import PrimaryButton from "./PrimaryButton.vue";


export default {
  name: "ConnectWallet",
components: { PrimaryButton },
  setup() {
    return {
      chainId: parseInt(process.env.VUE_APP_CHAIN),
    };
  },
};
</script>

<style scoped></style>
