import {ref, readonly} from 'vue';
import axios from 'axios';
import {useStore} from 'vuex';
import {getTokenName, getTokenSymbol} from "@/utils/helpers"
export default function useSearch(){
    const searching = ref(false);
    const results = ref([]);
    const gotResults = ref(false);
    const error = ref(false);

    const search = async (q) => {
        clear();

        try {
            searching.value = true;
            const searchRequest = await axios(
                process.env.VUE_APP_API_ENDPOINT + `/bitquery/search?query=${q}&key=${process.env.VUE_APP_API_KEY}`
            )
            
             let tokens = [];
            
            for(let i=0;i< searchRequest.data.search.length;i++){
                if(! searchRequest.data.search[i].subject) continue;
                let req;
                try {
                    req = await axios.get('https://api.coingecko.com/api/v3/coins/ethereum/contract/' + searchRequest.data.search[i].subject.address);
                    tokens.push({
                        name: req.data.name,
                        symbol: req.data.symbol,
                        image: req.data.images.large,
                        address: searchRequest.data.search[i].subject.address
                    })
                }catch(err){
                    try {
                        const name = await getTokenName(searchRequest.data.search[i].subject.address, process.env.VUE_APP_DEFAULT_ADDRES);
                        const symbol = await getTokenSymbol(searchRequest.data.search[i].subject.address, process.env.VUE_APP_DEFAULT_ADDRES);
                        tokens.push({
                            name, symbol, image: 'https://eu.ui-avatars.com/api/?name=' + name, address: searchRequest.data.search[i].subject.address
                        })
                    }catch(err){
                        console.error(err)
                    }
                }
            }
            results.value = tokens;
            searching.value = false;
            gotResults.value = true;
        }catch(err){
            error.value = err;
            searching.value = false;
            gotResults.value = true;
        }
    }

    const clear = () => {
        gotResults.value = false;
        results.value = [];
    }

    return {
        searching: readonly(searching),
        results: readonly(results),
        error: readonly(error),
        gotResults: readonly(gotResults),
        search, clear
    }
}