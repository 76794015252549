<template>
    <span>{{formattedAddress}}</span>
</template>

<script>
    export default {
        name: "DisplayAddress",
        props:[
            "address"
        ],
        computed:{
            formattedAddress(){
                console.log(this.address);
                return this.address.substring(0, 6) + '....' + this.address.substring(this.address.length - 4, this.address.length)
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>