<template>
  <div class="relative h-full ">
    <div
      class="
        text-white
        rounded-md
        px-4
        w-full
        component-wrapper
        h-full
        border
        relative
        z-20
      "
    >
      <swap-confirm-tokens-in />
      <swap-confirm-tokens-out />
      <p class="text-sm uppercase opacity-80">Slippage</p>
      <p class="mb-4 text-lg flex items-center">
        <span class="pr-8"> {{ $store.state.swap.slippage }}% </span>
        <vue3-slider v-model="slippage" color="#2B43C2" track-color="#131526" :min="1" :max="49" />
      </p>

      <div
        class="mb-4 bg-black-light rounded-lg py-2 px-6 text-base font-medium"
      >
        <div class="flex justify-between">
          <p>Price Impact:</p>
          <p>~{{ $store.getters.priceImpact }}%</p>
        </div>
        <div class="flex justify-between">
          <p>Gas Estimate:</p>
          <p>~${{ gas_in_usd }}</p>
        </div>
      </div>
      <connect-wallet>
        <div v-if="$store.state.swap.estimateGasError" class="bg-red-400 text-white rounded-sm mt-1 w-full text-center py-2">
          {{$store.state.swap.estimateGasError}}
        </div>
        <get-quote-button v-else-if="!trade" />
        <approve-swap-button v-else-if="!approved" />
        <do-swap-button v-else />
      </connect-wallet>
    </div>
  </div>
</template>

<script>
import slider from "vue3-slider";
import GetQuoteButton from "./GetQuoteButton.vue";
import DoSwapButton from "./DoSwapButton.vue";
import ConnectWallet from "./ConnectWallet.vue";
import Moralis from "moralis";
import ApproveSwapButton from "./ApproveSwapButton.vue";
const math = require("mathjs");
import SwapConfirmTokensIn from './SwapConfirmTokensIn.vue';
import SwapConfirmTokensOut from './SwapConfirmTokensOut.vue'

import {ETH_COIN_ADDRESS} from '@/utils/ethereumFunctions'
export default {
  name: "SwapConfirm",
  data() {
    return {
      pair: "",
    };
  },
  components: {
    "vue3-slider": slider,
    GetQuoteButton,
    ConnectWallet,
    ApproveSwapButton,
    DoSwapButton,
    SwapConfirmTokensIn,
    SwapConfirmTokensOut
  },
  computed: {
    slippage:{
      get(){
        return this.$store.state.swap.slippage;
      },
      set(val){
        this.$store.commit('setSlippage', val);
      }
    },
    approved() {
      if (!this.trade) return false;
      if (this.$store.state.swap.token_in_address === ETH_COIN_ADDRESS) return true; // This is eth addressd ddoesn't need approval
      return this.$store.getters.maxAmountIn <= this.$store.state.swap.token_in_allowance;
    },
    trade() {
      return this.$store.state.swap.trade;
    },
    gas_in_eth() {
      return this.$store.getters.gas;
    },
    gas_in_usd() {
      const eth = this.$store.getters.tokenByCa(ETH_COIN_ADDRESS);
      if(! eth) return 0;
      return  Math.round(math.multiply(eth.price, this.gas_in_eth) * 100) / 100;
    },  
  },
  methods: {
    buttonAction() {
      this.getQuote();
    },
  },
};
</script>

<style scoped>
.component-wrapper {
  padding: 20px 30px;
  background: #141628;
  border-color: #1d1e2e;
}
.wolf {
  position: absolute;
  height: 175px;
  width: 152px;
  transform: scaleX(-1);
  top: -105px;
  right: 10px;
}
</style>
