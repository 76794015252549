<template>
  <primary-button @click="doSwap">
    <font-awesome-icon
      v-if="loading"
      icon="spinner-third"
      class="mr-2"
      spin
    ></font-awesome-icon>
    {{ loading ? "Swapping..." : "Confirm Swap" }}
  </primary-button>
</template>

<script>
import PrimaryButton from "@/components/PrimaryButton";
export default {
  name: "DoSwapButton",
  components: {
    PrimaryButton,
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    this.$store.dispatch("estimateGasAction");
  },
  methods: {
    async doSwap() {
      this.loading = true;
      try {
        await this.$store.dispatch("sendTrade");
        this.loading = false;
      } catch (err) {
          if(err.code!==4001){
              this.error = err.message;
          }
        console.log(err);
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped></style>
