<template>
  <div
    :class="[
      isSelected ? 'card-selected' : '',
      ' transition-all cursor-move relative my-2 mx-2 rounded-lg  dark:bg-gray-800 hover:border-2 border-primary h-72 w-52 shadow-xl card-container flex flex-col',
    ]"
  >
    <div class="absolute top-0 left-0 z-10 h-72 w-52">
      <img
        v-if="token"
        :src="token.image"
        class="rounded-full absolute nonDragAreaSelector"
        style="left: 51px; top: 31px; width: 105px"
      />
    </div>

    <div class="absolute top-0 left-0 h-72 w-52 z-20 nonDragAreaSelector">
      <img
        src="https://ipfs-fast.b-cdn.net/shiryo-swap/card-background.png?width=208&height=288"
      />
    </div>

    <div v-if="token" class="absolute top-0 left-0 right-0 bottom-0 z-30">
      <p
        class="text-lg uppercase absolute text-white text-center"
        style="top: 137px; left: 45px; width: 115px"
      >
        {{ token.symbol }}
      </p>
      <p
        class="text-white absolute text-center text-base"
        style="top: 192px; left: 15px; right: 15px"
      >
        <span v-tippy="'Your Balance'">{{ balanceOfToken }}</span>
      </p>
      <p
        class="text-white absolute text-center text-base"
        style="top: 215px; left: 15px; right: 15px"
      >
        <span v-tippy="'Current Price'">{{ token.formatted_price }} </span>
      </p>
      <div
        class="flex text-white justify-center absolute lg:opacity-70"
        style="top: 245px; left: 45px; width: 115px"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
const math = require("mathjs");
export default {
  name: "CardBody",

  props: {
    token: {},
  },
  computed: {
    balanceOfToken() {
      return this.$store.getters.tokenBalance(this.token.token_address);
    },
    isSelected() {
      if (this.token.token_address === this.$store.state.swap.token_in_address)
        return true;
      if (this.token.token_address === this.$store.state.swap.token_out_address)
        return true;
      return false;
    },
  },
  methods: {
    viewContract() {
      const url =
        "https://etherscan.io/address/" + this.token_address + "#code";
      window.open(url, "_blank").focus();
    },
  },
};
</script>

<style scoped>
.card-container {
  /* transform: translate(0, 100px); */
  background: #141628;
  border: 1px solid #1d1e2e;
}
.card-container:hover {
  transition: 0.1s ease-in-out all;
  /* transform: translate(0, -5px); */
  transform: scale(1.05);
}
</style>
