  <!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="px-4">
    <h3 class="text-white text-xl mb-2">Import a token</h3>
    <import-token @close="$emit('close')" />
  </div>
</template>

<script>
import { ref } from "vue";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/outline";
import ImportToken from "./ImportToken.vue";

export default {
  name: "TokenSearchSlideOver",
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CheckIcon,
    ImportToken,
  },
  computed: {
    open() {
      return this.$store.state.tokens.showTokenSearchModal;
    },
  },
  methods: {
    close() {
      this.$store.commit("setShowTokenSearchModal", false);
    },
  },
};
</script>
