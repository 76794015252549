<template>
    <div class="flex mb-4 items-end">
          <div class="flex items-end w-full">
            <img :src="token.image" class="h-12 w-12 rounded-full" />
            <div>
              <h1 class="text-3xl text-white font-display ml-4 tracking-wider">
                {{ token.name }}
              </h1>

              <h3 class="text-xs ml-4 text-gray-300">
                SYMBOL: {{ token.symbol }}
              </h3>
            </div>
          </div>
        </div>
</template>

<script>
    export default {
        name:"TokenHeader",
        props:{
            token:{}
        }
    }
</script>

<style scoped>

</style>