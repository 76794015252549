<template>
  <div v-if="!token">
    <div class="h-72 w-52 mx-2">
      <ContentLoader
        viewBox="0 0 208 288"
        :speed="2"
        primaryColor="#131a37"
        secondaryColor="#313854"
      >
        <rect x="0" y="0" rx="10" ry="10" width="208" height="288" />
      </ContentLoader>
    </div>
  </div>
  <Draggable v-else-if="token && token.found">
    <card-body :token="token">
      <!-- <p class="text-white">{{card?.order.recent}}</p> -->
      <InformationCircleIcon
        @click="viewInfo"
        class="h-5 w-5 cursor-pointer mr-2"
      />
      <ChartBarIcon @click="viewChart" class="h-5 w-5 cursor-pointer" /> 
       <!-- <DocumentTextIcon 
            @click="viewContract"
            class="h-5 w-5 cursor-pointer"
          /> -->
    </card-body>
  </Draggable>
</template>

<script>
import { Draggable } from "vue3-smooth-dnd";

import find from "lodash/find";
import { ContentLoader } from "vue-content-loader";
import { SlideInOut } from "vue3-transitions";
import CardBody from "./CardBody.vue";
import {ChartBarIcon, InformationCircleIcon} from "@heroicons/vue/outline"
const math = require("mathjs");
export default {
  name: "Card",
  props: {
    token_address: String,
    order: {},
  },
  components: {
    Draggable,
    ContentLoader,
    SlideInOut,
    ChartBarIcon,
    CardBody,
    InformationCircleIcon
  },
  computed: {
    token() {
      return this.$store.getters.tokenByCa(this.token_address);
    },
    card() {
      const card = this.$store.state.cards.cards[this.token_address];
      return (card) ?card : {};
    },


  },
  mounted() {
    if (!this.token) {
      this.$store.dispatch("getTokenByCa", this.token_address);
    }
  },
  methods: {
    viewInfo() {
      this.$store.commit("setActiveTokenInfo", this.token_address);
    },
    viewChart() {
      this.$store.commit("setActiveTokenChart", this.token_address);
    },
  },
};
</script>
<style scoped></style>
<style>
/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
