<template>
  <token-overview />
  <token-chart />
  <swap-success-modal />
  <swap-error-modal />
  <token-select-slide-over />
  <no-meta-mask />

    <div class="overlay z-20 absolute left-0 top-0 right-0 bottom-0"></div>
    <div class="flex h-full ">
    <page-loader>
      <div class="hidden xl:flex flex-grow  h-screen relative z-40 sidebar"
        style="width: 220px"
      >
        <Transition name="slide-fade" appear>
          <div
            class="bg-black h-full"
            style="width: 220px"
          >
            <sidebar />
          </div>
        </Transition>
      </div>
      <div class="flex-grow relative flex items-center min-h-screen ">
        <Transition name="scale" mode="out-in" appear>
          <main-content />
        </Transition>
      </div>
    </page-loader> 
   </div>
</template>

<script>
import Sidebar from "./components/Sidebar.vue";
import PageLoader from "./components/PageLoader.vue";
import MainContent from "./components/MainContent.vue";
import TokenOverview from "./components/TokenOverview.vue";
import TokenChart from "./components/tokenChartModal/TokenChart.vue";
import SwapSuccessModal from "./components/SwapSuccessModal.vue";
import SwapErrorModal from "./components/SwapErrorModal.vue";
import TokenSelectSlideOver from './components/TokenSelectSlideOver.vue'
import TokenSearchModal from './components/TokenSearchSlideOver.vue';
import NoMetaMask from './components/NoMetaMask.vue';

export default {
  name: "App",
  components: {
    Sidebar,
    PageLoader,
    MainContent,
    TokenOverview,
    TokenChart,
    SwapErrorModal,
    SwapSuccessModal,
    TokenSelectSlideOver,
    TokenSearchModal,
    NoMetaMask
  },
  data() {
    return {};
  },
  mounted() {
    //    this.$store.commit('setCards', tokensFromStorage.concat)
    //this.$store.commit("addHistoryToken");
  },
  methods: {
    onDrop() {
      
    },
  },
};
</script>
<style scoped>
.top-container {
  position: absolute;
  top: 0px;
  left: 0;
  width: 80vw;
}
.bottom-container {
  position: absolute;
  bottom: 0px;
  left: 0;
  overflow-x: scroll;
  width: 80vw;
  overflow: hidden;
  height: 400px;
}
.bottom-container-cards {
  height: 310px;
  right: 8px;
  border-bottom-right-radius: 10px;
  overflow: hidden;

  bottom: 0px;
  position: absolute;
  left: 250px;
}
.swap-container {
  position: absolute;
  top: 30px;
  right: 0;
  /* background: black; */
  /* bottom: 14rem; */
  left: 0;
}
.logo {
  position: absolute;
  left: 15px;
  top: 25px;
}
.deck {
  position: absolute;
}
.top-deck {
  top: 10px;
  left: 10px;
}
.bottom-deck {
  left: 25px;
  bottom: 30px;
}
.overlay {
  background: rgba(17, 17, 32, 1);
  background: linear-gradient(
    to top,
    rgba(17, 17, 32, 1) 30%,
    rgba(17, 17, 32, 0)
  );
}
.sidebar {
}
</style>
