<template>
  <modal title="Token Overview" :open="open" @close="close">
    <div v-if="token" class="p-8 grid grid-cols-1 md:grid-cols-2 gap-x-12">
      <div>
        <!--  Header  -->
        <token-header :token="token" />
        <div class="flex mt-4 py-3 align-center items-center">
          <p class="uppercase font-medium mr-4 text-gray-400">Network:</p>
          <p class="flex text-gray-400">
            <img src="/img/eth.png" class="h-5 w-5 mr-2" />
            Ethereum
          </p>
        </div>
        <div class="flex py-3 text-gray-400 items-center">
          <p class="uppercase font-medium mr-4">Links:</p>
          <token-links :links="token.links" />
        </div>
        <!-- Meta  -->
        <div class="text-gray-400 mb-4 pt-4">
          <!-- <token-description :token="token" /> -->
          <p>{{ token.description }}</p>
        </div>
      </div>
      <div class="text-gray-400 mb-4 text-sm">
        <div class="flex py-3 border-b border-t border-black-lighter">
          <p class="uppercase font-medium mr-4 ml-2">Contract:</p>
          <p class="truncate">{{ token.token_address ?? "n/a" }}</p>
        </div>
        <div class="flex py-3 border-b border-black-lighter">
          <p class="uppercase font-medium mr-4 ml-2">Market Cap:</p>
          <p>{{ token.market_cap }}</p>
        </div>
        <div class="flex py-3 border-b border-black-lighter">
          <p class="uppercase font-medium mr-4 ml-2">Daily Volume:</p>
          <p>{{ token.daily_volume }}</p>
        </div>
        <div class="flex py-3 border-b border-black-lighter">
          <p class="uppercase font-medium mr-4 ml-2">Circulating Supply:</p>
          <p>{{ token.circulating_supply }}</p>
        </div>

        <div class="flex py-3 border-b border-black-lighter">
          <p class="uppercase font-medium mr-4 ml-2">Price:</p>
          <p>{{ token.formatted_price }}</p>
        </div>
        <div class="flex py-3 border-b border-black-lighter">
          <p class="uppercase font-medium mr-4 ml-2">1 Day:</p>
          <p v-if="token">
            <price-change-percent
              :percent="token.price_change_24h_percent"
            />
          </p>
        </div>
        <div class="flex py-3 border-b border-black-lighter">
          <p class="uppercase font-medium mr-4 ml-2">7 Days:</p>
          <p v-if="token">
            <price-change-percent
              :percent="token.price_change_7d_percent"
            />
          </p>
        </div>
        <div class="flex py-3 border-b border-black-lighter">
          <p class="uppercase font-medium mr-4 ml-2">14 Days:</p>
          <p v-if="token">
            <price-change-percent
              :percent="token.price_change_14d_percent"
            />
          </p>
        </div>
        <div class="flex py-3 border-b border-black-lighter">
          <p class="uppercase font-medium mr-4 ml-2">30 Days:</p>
          <p v-if="token">
            <price-change-percent
              :percent="token.price_change_30d_percent"
            />
          </p>
        </div>
        <div class="flex py-3 border-b border-black-lighter">
          <p class="uppercase font-medium mr-4 ml-2">ATH:</p>
          <p v-if="token" class="text-green-500">{{ token.ath }}</p>
        </div>
        <div class="flex py-3 border-b border-black-lighter">
          <p class="uppercase font-medium mr-4 ml-2">ATL:</p>
          <p v-if="token" class="text-red-500">{{ token.atl }}</p>
        </div>
      </div>
    </div>

    <!-- Misc -->
  </modal>
</template>

<script>
import PriceChangePercent from "./PriceChangePercent.vue";
import TokenLinks from "./TokenLinks.vue";
import TokenDescription from "./TokenDescription.vue";
import { formatStringWithCommas } from "@/utils/helpers";
import Modal from "./Modal.vue";
import TokenHeader from "./TokenHeader.vue";
const math = require("mathjs");
export default {
  name: "TokenOverview",
  components: {
    Modal,
    PriceChangePercent,
    TokenLinks,
    TokenDescription,
    TokenHeader,
  },
  computed: {
    open() {
      return (
        this.$store.state.tokens.activeTokenInfo &&
        this.$store.state.tokens.activeTokenInfo.length > 0
      );
    },
    token() {
      return this.$store.getters.activeInfoToken;
    },
  },
  methods: {
    close() {
      this.ready = false;
      this.$store.commit("setActiveTokenInfo", false);
    },
    getToken() {
      console.log("get token");
    },
  },
  watch: {
    open(newVal) {
      if (newVal) {
        if (this.token) {
          this.ready = true;
        } else {
          this.getToken();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
