<template>
  <div class="mt-4">
    <div class="mt-1 relative rounded-md shadow-sm">
      <div
        v-if="allowmax"
        class="
          absolute
          right-2
          top-2
          bottom-2
          px-3
          py-2
          z-20
          flex
          items-center
          bg-black-lighter
          rounded-lg
          cursor-pointer
          hover:text-white
          text-gray-500
        "
        @click="setMax"
      >
        <span class="sm:text-sm" id="price-currency"> MAX </span>
      </div>
      <input
        type="text"
        name="price"
        id="price"
        class="
          bg-black-light
          block
          w-full
          pl-4
          py-3
          z-10
          pr-12
          rounded-xl
          border-0
          text-lg
          text-white
        "
        v-model="_amount"
        placeholder="0.00"
        aria-describedby="price-currency"
      />
    </div>
  </div>
</template>

<script>
import web from "web3";

export default {
  name: "AmountsInput",
  props: {
    allowmax: {},
    amount: {},
  },
  computed: {
    _amount: {
      get() {
        return this.amount;
      },
      set(val) {
        this.$emit("update", val);
      },
    },
  },
  methods: {
    setMax() {
      this.$emit("updateMax");
    },
  },
};
</script>

<style lang="scss" scoped></style>
