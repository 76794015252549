import take from 'lodash/take'

export default {
    state:{
        transactions: {
            "0xB0150e12E12fEEaceac5030b530AB5B10a3fd67E": [
                "0x6218955fc3847f40b0360ba8ec30d6b3395a6d5d8b38646d194012aa74a3d380",
            
        
            ]
        }
    },
    getters: {
        previousTransactions(state, getters, rootState){
            if(!rootState.eth.connected_address) return [];
            return take(state.transactions[rootState.eth.connected_address], 7);
        }
    },
    mutations: {
        newTransaction(state, {address, transaction}){
            if(! state.transactions[address]){
                state.transactions[address] = [transaction];
            }
            else if( ! state.transactions[address].includes(transaction)){
                state.transactions[address].unshift(transaction);
            }
        }
    },
    actions: {
        addTransaction({state, rootState, commit}, transaction){
            commit(
                'newTransaction', {
                    address: rootState.eth.connected_address, 
                    transaction
                }
            )
        }
    },
}