<template>
      <p :class="[increase ? 'text-green-600' : 'text-red-600', 'ml-2 flex items-baseline text-sm font-semibold ']">
            <ArrowSmUpIcon v-if="increase" class="self-center flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
            <ArrowSmDownIcon v-else class="self-center flex-shrink-0 h-5 w-5 text-red-500" aria-hidden="true" />
            <span class="sr-only"> {{ increase ? 'Increased' : 'Decreased' }} by </span>
            {{ percent }}%
          </p>
</template>

<script>
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/vue/solid'
    export default {
        name:"PriceChangePercent",
        props:{
            percent:{}
        },
        components:{
             ArrowSmDownIcon, ArrowSmUpIcon
        },
        computed:{
            increase(){
                return ! this.percent.toString().startsWith('-')
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>