<template>
  <div class="w-52 relative">
    <div class="flex space-between items-center">
      <img
        v-if="ready"
        :src="token.image"
        class="h-16 w-16 mx-auto border border-black-lighter rounded-full"
      />
       <div class="flex-grow pl-4">
      <h2 v-if="ready" class="text-gray-50  uppercase text-xl">
        {{ token.name }}
      </h2>
      <h3 v-if="ready" class="text-gray-400  text-base">
        {{ formattedAddress }}
      </h3>
    </div>
    </div>
   
    <div
      class="
        border-black-lighter
        flex
        text-white
        justify-center
        border-t
        pt-4
        mt-4
      "
    >
      <InformationCircleIcon
        @click="viewInfo"
        class="h-6 w-6 opacity-70 cursor-pointer mr-4"
      />
      <ChartBarIcon
        @click="viewChart"
        class="h-6 w-6 opacity-70 cursor-pointer"
      />
    </div>
    <div
      class="
        border-black-lighter
        flex
        text-white
        justify-center
        border-t
        pt-4
        mt-4
      "
    >
      <h3
        class="text-gray-400 text-center text-lg cursor-pointer hover:text-white"
        @click="$emit('removeCard')"
      >
        REMOVE
      </h3>
    </div>
  </div>
</template>

<script>
import { InformationCircleIcon, ChartBarIcon } from "@heroicons/vue/outline";
import {ethToken, ETH_COIN_ADDRESS} from '@/utils/ethereumFunctions'
export default {
  name: "AmountsCardDropped",
  props: {
    token_address: String,
  },
  components: {
    InformationCircleIcon,
    ChartBarIcon,
  },
  computed: {
    token() {
      return  this.$store.getters.tokenByCa(this.token_address);
    },
    formattedAddress() {
      if (!this.token) return "";
      if(this.token_address===ETH_COIN_ADDRESS) return 'ethereum'
      return (
        this.token.token_address.substring(0, 6) +
        "...." +
        this.token.token_address.substring(
          this.token.token_address.length - 4,
          this.token.token_address.length
        )
      );
    },
  },
  data() {
    return {
      ready: false,
    };
  },
  mounted() {
    if (!this.token) {
      this.$store.dispatch("getTokenByCa", this.token_address);
    } else {
      this.ready = true;
    }
  },
  methods: {
    viewInfo() {
      this.$store.commit("setActiveTokenInfo", this.token_address);
    },
    viewChart() {
      this.$store.commit("setActiveTokenChart", this.token_address);
    },
  },
  watch: {
    token(newVal) {
      if (newVal) this.ready = true;
    },
  },
};
</script>

<style scoped></style>
