<template>
  <Container
    drop-class="hide"
    group-name="cards"
    behaviour="drop-zone"
    @drop="onDrop"
    :onDragEnter="onDragEnter"
    :onDragLeave="onDragLeave"
  >
    <amounts-card
      heading="Amount Out"
      :address="$store.state.swap.token_out_address"
      context="out"
      :hovered="hovered"
      :balance="balance"
      :symbol="symbol"
      :amount="$store.state.swap.token_out_amount"
      @removeCard="removeCard"
      @updateValue="updateValue"
    />
  </Container>
</template>

<script>
import { Container } from "vue3-smooth-dnd";
import AmountsCard from "./AmountsCard.vue";
import { ChainId, Token } from "@uniswap/sdk";
export default {
  components: { AmountsCard, Container },
  name: "AmountsOut",
  computed: {
    balance() {
      return this.$store.getters.tokenBalance(
        this.$store.state.swap.token_out_address
      );
    },
    symbol() {
      return this.$store.getters.tokenByCa(
        this.$store.state.swap.token_out_address
      ).symbol;
    },
  },
  data() {
    return {
      hovered: false,
    };
  },
  mounted() {
    if (! this.$store.state.swap.token_out_address) {
      this.$store.dispatch("addStartCard", {
        ca: "0x1e2f15302b90edde696593607b6bd444b64e8f02",
        position: "out",
      });
    }
  },
  methods: {
    onDrop(dropItem) {
      this.hovered = false;
      if (dropItem.addedIndex === null && dropItem.removedIndex == null) return;

      if (
        dropItem.removedIndex === null &&
        this.$store.state.swap.token_out_address
      ) {
        // This catches when the card is replaced and adds the old card back to the sort order
        this.removeCard();
      }

      if (typeof dropItem.addedIndex !== undefined) {
        this.$store.dispatch("addCardToStaging", {
          ca: dropItem.payload.ca,
          position: "out",
        });
      } else if (typeof dropItem.removedIndex !== undefined) {
        //this.removeCard();
      }
    },
    removeCard() {
      this.$store.dispatch("removeCardFromStaging", {
        ca: this.$store.state.swap.token_out_address,
        position: "out",
      });
    },
    onDragEnter() {
      this.hovered = true;
    },
    onDragLeave() {
      this.hovered = false;
    },
    updateValue(val) {
      this.$store.commit("setTokenOutAmount", val);
    },
  },
};
</script>

<style lang="scss" scoped></style>
