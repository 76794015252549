<!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<template>
  <div class="relative rounded-md flex-grow ">
    <div
      class="
        absolute
        inset-y-0
        left-0
        pl-3
        flex
        items-center
        pointer-events-none
        text-gray-400
      "
    >
      <font-awesome-icon v-if="searching" icon="spinner-third" spin />
      <SearchIcon v-else class="h-5 w-5" aria-hidden="true" />
    </div>
    <input
     @keyup.enter="debounceSearch"
      type="text"
      name="search"
      id="search"
      @input="debounceSearch"
      class="
        text-white
        block
        w-full
        pl-10
        text-lg
        border-gray-500
        rounded-md
        bg-transparent
      "
      placeholder="Search for currency"
    />
    <div v-if="gotResults" class="absolute z-50  xl:bg-black py-2 px-2 rounded-xl result-container " style="">
      <p v-if="! results.length" class="text-white text-xl my-4 px-4">No Results</p>
      <ul v-else class="w-full ">
        <li @click="clear" class="hidden xl:block text-right cursor-pointer rounded-full absolute close-icon uppercase font-semibold p-1  xl:p-1 text-gray-50 ">
          <XIcon class="h-4 w-4  text-black-light"/>
        </li>
        <search-result  v-for="(item, index) in results" :key="item.address" :item="item" @select="importToken" />
      </ul>
    </div>
  </div>
</template>

<script>
import { SearchIcon, XIcon} from "@heroicons/vue/solid";
import useSearch from "@/logic/useSearch";
import SearchResult from './SearchResult.vue'

export default {
  name: "ImportToken",
  setup() {
    const { searching, search, error, results, gotResults, clear } = useSearch();
    return { searching, search, error, results, gotResults, clear };
  },
  methods: {
    debounceSearch(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        if(event.target.value){
          this.search(event.target.value)
        }
      }, 600);
    },
    async importToken(address){
      this.$emit('close');
      await this.$store.dispatch('importToken', address);
      this.clear();
    },
  },
   data(){
      return {
        importing: false
      }
    },
  components: {
    SearchIcon, SearchResult, XIcon
  },
};
</script>
<style scoped>
.c-input {
  border: 1px solid #1d1e2e;
}
.close-icon {
  right: -5px;
  top: 10px;
  background: white;
}
.result-container {
  top:50px;
  left:0px;
  right: 0px;
}
	@media (min-width: 1280px) { 
.result-container {
  width: 650px;
}
.close-icon {
  right: -11px;
  top: -11px;
  background: white;
}
   }

</style>
