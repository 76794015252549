import { library } from '@fortawesome/fontawesome-svg-core'
import { 
    faHome, faMobileAlt, faPawAlt, faFarm, faStoreAlt, faComet, faEgg,
    faGlobe, faStickyNote, faCircleNotch, faExternalLink, faSave, faAngleLeft,
    faSignIn, faColumns, faChartLine, faChartNetwork, faExchange, faAd, faWallet,
    faHistory, faNetworkWired, faUsdCircle, faCoins, faUserAstronaut, faSearch, faFilter,
    faArrowUp, faArrowDown, faText, faEye, faLongArrowRight, faLongArrowLeft, faCheck, faSpinnerThird
} from '@fortawesome/pro-solid-svg-icons'
import {
    faTwitter, faTelegram, faDiscord, faFacebookF, faGithub, faMediumM, faRedditAlien, faTelegramPlane, faYoutube, faLinkedin,
    faBitcoin
} from '@fortawesome/fontawesome-free-brands'

library.add( 
    faHome, faMobileAlt, faPawAlt, faFarm, faStoreAlt, faComet, faEgg,
    faGlobe, faStickyNote, faTelegram, faTwitter, faCircleNotch, faExternalLink,
    faSave, faAngleLeft, faSignIn,faColumns, faChartLine, faChartNetwork, faExchange, faAd,
    faWallet, faHistory, faNetworkWired, faUsdCircle, faCoins, faUserAstronaut, faSearch, faFilter,
    faArrowUp, faArrowDown, faText, faDiscord,  faFacebookF, faGithub, faMediumM, faRedditAlien, 
    faTelegramPlane, faYoutube, faLinkedin, faBitcoin, faEye, faLongArrowRight, faLongArrowLeft,
    faCheck, faSpinnerThird
    )
