<template>
  <div class="h-full flex items-center">
        <vue3-slider v-model="myNumber" color="#2B43C2" track-color="#131526" />
    </div>
</template>

<script>
import slider from "vue3-slider";
    export default {
        name: "AmountsSlider",
        components:{
            "vue3-slider": slider,
        },
        data(){
            return {
                myNumber: 0
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>