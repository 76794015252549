<template>
 <primary-button v-if="bothAmounts">
    Enter Amount In OR Amount Out
  </primary-button>
  <primary-button v-else-if="noAmount">
    Enter An Amount
  </primary-button>
  <primary-button v-else-if="notEnoughTokens">
    Not Enough Tokens
  </primary-button>
  <primary-button v-else @click="_getQuote">
    <font-awesome-icon
      v-if="loading"
      icon="spinner-third"
      class="mr-2"
      spin
    ></font-awesome-icon>
    {{ loading ? "Getting Quote..." : "Get Quote" }}
  </primary-button>
</template>

<script>
import PrimaryButton from "@/components/PrimaryButton";
import Moralis from "moralis";
const math = require("mathjs");

export default {
  name: "GetQuoteButton",
  components: { PrimaryButton },
  computed: {
    loading(){
      return this.$store.state.swap.getting_trade
    },
    noAmount(){
      return (! this.$store.state.swap.token_in_amount && ! this.$store.state.swap.token_out_amount);
    },
    bothAmounts(){
      return (this.$store.state.swap.token_in_amount && this.$store.state.swap.token_out_amount);
    },
    tokenInAmount(){
      return Number(this.$store.state.swap.token_in_amount)
    },
    notEnoughTokens(){
      return ( this.tokenInAmount > this.balance)
    },
    balance() {
      return Number(this.$store.getters.tokenBalance(
        this.$store.state.swap.token_in_address
      ));
    },
  },
  methods: {
    async _getQuote() {
      if(this.$store.state.swap.token_in_amount){
        this.$store.commit('setTradeType', 'EXACT_INPUT')
        await this.$store.dispatch('getTrade');
      }else {
        this.$store.commit('setTradeType', 'EXACT_OUTPUT')
        await this.$store.dispatch('getTrade');
      }
     // 9971230000000
    },
  },
  watch: {
    quote(newVal) {
      this.$store.commit("setQuote", newVal);
    },
  },
};
</script>

<style scoped></style>
