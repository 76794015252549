<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-50 inset-0 overflow-y-auto" @close="_close">
      <div
        class="
          flex
          items-end
          justify-center
          min-h-screen
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="
              inline-block
              align-bottom
              bg-black-light
              rounded-lg
              px-4
              pt-5
              pb-4
              text-left
              overflow-hidden
              shadow-xl
              transform
              transition-all
              sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6
            "
          >
            <div>
              <div
                class="
                  mx-auto
                  flex
                  items-center
                  justify-center
                  h-12
                  w-12
                  rounded-full
                  bg-green-100
                "
              >
                <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <DialogTitle
                  as="h3"
                  class="text-2xl leading-6 font-medium text-gray-50"
                >
                  Swap Successful
                </DialogTitle>
                <div class="mt-4">
                  <p class="text-lg text-gray-100 opacity-90 tracking-wider">
                    Your token swap was executed successfully. Click the link
                    below to view the transaction on etherscan<br />
                  </p>
                  <p class="pt-4 text-gray-50 tracking-wider text-lg">
                    <a :href="href" target="_blank" class="hover:underline"
                      >View Transaction on EtherScan</a
                    >
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-6">
              <primary-button xl class="w-full" @click="_close"
                >Back To Dashboard</primary-button
              >
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from "vue";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/outline";
import PrimaryButton from "./PrimaryButton.vue";

export default {
  name: "SwapSuccessModal",
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CheckIcon,
    PrimaryButton,
  },
  mounted(){

  },
  computed: {
    open() {
      return this.$store.state.swap.swapSuccess.length > 0;
    },
    href() {
      return "https://etherscan.io/tx/" + this.$store.state.swap.swapSuccess;
    },
  },
  methods: {
    _close() {
      this.$store.dispatch('addTransaction', this.$store.state.swap.swapSuccess);
      //this.$store.commit("setSuccess", false);
      window.location.reload();
    },
  },
};
</script>
