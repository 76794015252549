<template>
  <p class="uppercase text-sm tracking-wide font-medium opacity-70">
        {{ (isExactInput) ? 'Expected Tokens Out' : 'Tokens Out' }}
  </p>
  <p class="mb-4 text-lg font-medium uppercase">
    {{ $store.getters.maxAmountOut }} {{ token_out.symbol }} 
  </p>
  <p v-if="isExactInput" class="uppercase text-sm font-medium opacity-70">Minimum Output</p>
  <p v-if="isExactInput" class="mb-4 text-lg font-medium uppercase">
    {{ $store.getters.minAmountOut }} {{ token_out.symbol }} 
  </p>
</template>

<script>
export default {
  name: "SwapConfirmTokensOut",
  computed: {
    isExactInput() {
      return this.$store.state.swap.trade_type === "EXACT_INPUT";
    },
    token_out() {
      return this.$store.getters.tokenByCa(
        this.$store.state.swap.token_out_address
      );
    },
  },
};
</script>

<style scoped></style>
