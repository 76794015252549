<template>
  <div class="component-wrapper rounded-md w-full h-full">
    <div class="flex justify-between items-center">
      <h1 class="text-white font-display">{{heading}}</h1>
      <!-- <div class="flex-grow pl-4 ">
          <amounts-slider v-if="allowmax" />
      </div> -->
      <p v-tippy="'Balance'" class="text-sm tracking-wide font-medium opacity-70  uppercase text-white">{{balance}}</p>
    </div>
    <amounts-input :allowmax="allowmax" :amount="amount" @update="update" @updateMax="updateMax"/>
    <amounts-drop-area :address="address"  :context="context" @removeCard="$emit('removeCard')" :hovered="hovered"/>
  </div>
</template>

<script>
import AmountsSlider from './AmountsSlider.vue';
import AmountsInput from './AmountsInput.vue'
import AmountsDropArea from './AmountsDropArea.vue'

export default {
  name: "AmountsCard",
  props:{
    heading:{},
    address:{},
    allowmax:{},
    hovered: {},
    balance: {} ,
    amount: {},
    symbol:{},
    context:{}
  },
  components: {
    AmountsSlider,AmountsInput,AmountsDropArea
  },
  methods:{
    update(val){
      this.$emit('updateValue', val)
    },
    updateMax(){
      this.$emit('updateValue', 'max')
    }
  }
};
</script>

<style scoped>
.component-wrapper {
  background: linear-gradient(to top, rgba(20, 29, 50), rgba(41, 51, 84));
  padding: 32px 40px;
}
</style>
