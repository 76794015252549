import Moralis from 'moralis';
import axios from 'axios';
import sortBy from 'lodash/sortBy'
import reduce from 'lodash/reduce';
import filter from 'lodash/filter';
import find from 'lodash/find';
import {
    ETH_COIN_ADDRESS
} from '@/utils/ethereumFunctions';
import {
    slotFlagsText
} from '@vue/shared';
import {
    getTokenBalance
} from '../utils/ethereumFunctions';
import forEach from 'lodash/forEach'

export default {
    state() {
        return {
            activeList: 'common',
            showTokenInfo: false,
            gotTrending: false,
            cards: {
                "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2": {
                    ca: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
                    order: {
                        common: 1
                    },
                    type: 'draggable'
                },
                "0x6b175474e89094c44da98b954eedeac495271d0f": {
                    ca: "0x6b175474e89094c44da98b954eedeac495271d0f",
                    order: {
                        common: 2
                    },
                    type: 'draggable'
                },
                "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48": {
                    ca: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
                    order: {
                        common: 3
                    },
                    type: 'draggable'
                },
                "0xdac17f958d2ee523a2206206994597c13d831ec7": {
                    ca: "0xdac17f958d2ee523a2206206994597c13d831ec7",
                    order: {
                        common: 4
                    },
                    type: 'draggable'
                },
                "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599": {
                    ca: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
                    order: {
                        common: 5
                    },
                    type: 'draggable'
                },
                [ETH_COIN_ADDRESS]: {
                    ca: ETH_COIN_ADDRESS,
                    order: {
                        common: 6,
                        wallet: 1
                    },
                    type: 'draggable'
                },
                "0x1e2f15302b90edde696593607b6bd444b64e8f02": {
                    ca: "0x1e2f15302b90edde696593607b6bd444b64e8f02",
                    order: {
                        common: 7,
                        wallet: 2
                    },
                    type: 'draggable'
                }
            }
        }
    },
    getters: {
        getActiveList(state, getters) {
            const list = getters.getListByName(state.activeList)
            return sortBy(list, [(o) => {
                return o.order[state.activeList]
            }]);
        },
        getListByName: (state) => name => {
            const items =  filter(state.cards, (card) => {
                return (card.order[name] > 0)
            });
            return items;
        },

    },
    mutations: {
        setCardMeta(state, cardMeta) {
            state.cards[cardMeta.address] = cardMeta;
        },
        setShowTokenInfo(state, contract) {
            state.setShowTokenInfo = contract;
        },
        setActiveList(state, val) {
            state.activeList = val;
    },
    saveCards(state, cards) {
            state.cards = cards;
        },
        setGotTrending(state, val){
            state.gotTrending = val;
        },
        addCards(state, {cards, list}){
            forEach(cards, card => {
                if(state.cards[card.ca.toLowerCase()]){
                    console.log(state.cards[card.ca.toLowerCase()])
                    state.cards[card.ca.toLowerCase()].order[list] = card.order[list];
                }else {
                    state.cards[card.ca.toLowerCase()] = card;
                }
            });
        },
        clearWallet(state){
            state.cards = reduce(state.cards, (result, card, ca) => {
                if(card.order.wallet){
                    delete card.order.wallet;
                }
                result[ca] = card;
                return result;
            }, {});
        }
    },
    actions: {
        // Checks no cards are stuck on 0 order
        checkAndSetCard({rootState, commit, getters}, cards){
            const _cards = reduce(cards, (result, card, index) => {
                let _card = card;
                forEach(card.order, (_n, _k) => {
                    if(_n===0 && card.ca !== rootState.swap.token_in_address && card.ca !== rootState.swap.token_out_address){
                        // this ones stuck so move to the end of the list
                        const list = getters.getListByName(_k);
                        _card.order[_k] = list.length +1
                    }
                    result[card.ca] = _card;
                })
                return result;
            }, {});
            commit('saveCards', _cards)
        },
        async importToken({
            commit,
            dispatch,
            state
        }, address) {
            commit('setActiveList', 'recent');
            commit('addCards', {
                cards:[{
                    ca: address,
                    order: {
                        recent: 0
                    },
                    type: "draggable",
                }],
                list:'recent'
        });
            dispatch('removeCardFromStaging', {
                ca: address,
                position: false
            })
        },
        async getTrendingTokens({
            commit
        }) {
            const req = await axios.get(process.env.VUE_APP_API_ENDPOINT + "/cmc/trending?limit=50&key="+process.env.VUE_APP_API_KEY);
            const trending = reduce(req.data, (result, item, index) => {
                result.unshift({
                    ca: item.platform.token_address,
                    order: {
                        trending: index
                    },
                    type: "draggable",
                });
                return result
            }, []);
            commit('addCards',{cards: trending, list: 'trending'});
            commit('setGotTrending', true);
        },
        // Handles the drag and drop movemnt of cards
        moveCardInList({
            commit,
            state,
            dispatch
        }, {
            ca,
            addToPosition,
            movedFromPosition,
            list
        }) {
            const cards = reduce(state.cards, (result, card, address) => {
                if(card.order[list] === 0) {
                    result[address] = card;
                }
                else if( card.ca.toLowerCase() === ca.toLowerCase() ){
                    result[address] = card;
                    result[address].order[list] = addToPosition;
                }
                else if (
                    card.order[list] > movedFromPosition &&
                    card.order[list] <= addToPosition 
                ) {
                    result[address] = card;
                    result[address].order[list] = card.order[list] - 1;
                }
                else if (
                    card.order[list] < movedFromPosition &&
                    card.order[list] >= addToPosition 
                ) {
                    result[address] = card;
                    result[address].order[list] = card.order[list] + 1;
                }
                else {
                    result[address] = card;
                }
                return result;
            }, {});

            dispatch('checkAndSetCard', cards)
        },
        addCardToStaging({
            state,
            dispatch,
            commit
        }, {
            ca,
            position
        }) {
            const token = state.cards[ca.toLowerCase()];

            // Set the token in swap
            if (position == "in") dispatch('setTokenIn', ca);
            if (position == "out") dispatch('setTokenOut', ca);

            // Move items in its lists up by 1 place
            const listsToAdjust = reduce(token.order, (result, order, list) => { result[list] = order;return result}, []);
            const cards = reduce(state.cards, (result, card, address) => {
                let _card = card;
                if( card.ca.toLowerCase() === ca.toLowerCase() ){
                    forEach(card.order, (order, list)=>{
                        _card.order[list] = 0;
                    });
                }
                else{
                    forEach(card.order, (order, list)=>{
                        if(Object.keys(listsToAdjust).includes(list) && card.order[list] >  listsToAdjust[list]){
                            _card.order[list] = _card.order[list] - 1;
                        }
                    });
                }
                result[address] = _card;
                return result;
            }, {});
            dispatch('checkAndSetCard', cards)
        },
        addStartCard({
            state,
            dispatch,
            commit
        }, {
            ca,
            position
        }) {
            const token = state.cards[ca.toLowerCase()];

            // Set the token in swap
            if (position == "in") dispatch('setTokenIn', ca);
            if (position == "out") dispatch('setTokenOut', ca);

            // Move items in its lists up by 1 place
            const cards = reduce(state.cards, (result, card, address) => {
                let _card = card;
                if( card.ca.toLowerCase() === ca.toLowerCase() ){
                    forEach(card.order, (order, list)=>{
                        _card.order[list] = 0;
                    });
                }
                result[address] = _card;
                return result;
            }, {});
            dispatch('checkAndSetCard', cards)
        },
        removeCardFromStaging({
            state,
            dispatch,
            commit
        }, {
            ca,
            position
        }) {
            const token = state.cards[ca.toLowerCase()];
            // Clear the token
            if (position == "in") dispatch('setTokenIn', false);
            if (position == "out") dispatch('setTokenOut', false);

            let listsToAdjust = reduce(token.order, (result, order, list) => { if(list!=='recent'){result.push(list)}return result}, []);
            if(ca === ETH_COIN_ADDRESS && ! listsToAdjust.includes('wallet')){
                listsToAdjust.push('wallet');
            }
            if(ca === "0x1e2f15302b90edde696593607b6bd444b64e8f02" && ! listsToAdjust.includes('wallet')){
                listsToAdjust.push('wallet');
            }
            const cards = reduce(state.cards, (result, card, address) => {
                let _card = card;
                
                if( card.ca.toLowerCase() === ca.toLowerCase() ){
                    forEach(card.order, (order, list)=>{
                        _card.order[list] = 1;
                    });
                    _card.order['recent'] = 1;
                }
                else{
                    forEach(card.order, (order, list)=>{
                        if(listsToAdjust.includes(list) &&  _card.order[list] > 0){
                            _card.order[list] = _card.order[list] + 1;
                        }
                    });
                    if( _card.order['recent'] ){
                        if(_card.order['recent'] > 9){
                            _card.order = reduce(_card.order, (result, order, list)=>{
                                if(list!=='recent'){
                                    result[list] = order;
                                }
                                return result;
                            }, {})
                        }else {
                            _card.order['recent'] = _card.order['recent'] + 1
                        }
                    }
                }
                result[address] = _card;
                return result;
            }, {});
            dispatch('checkAndSetCard', cards)
        },
    }
}