import Web3 from 'web3'


export default {
    state() {
        return {
            web3: {},
            connected_address: "",
            connected_network: "",
            eth_balance: "",
            shiryo_balance: "",
            token_balances:{},
            eth_price: "",
            show_no_metamask: false
        }
    },
    getters: {
        connected(state) {
            return (
                parseInt(process.env.VUE_APP_CHAIN) === state.connected_network &&
                state.connected_address
            );
        },
    },
    mutations: {
        updateWeb3(state, web3) {
            state.web3 = web3;
        },
        updateBalance(state, balance) {
             state.eth_balance = balance;
        },
        updateNetwork(state, network) {
            state.connected_network = network;
        },
        updateAddress(state, connected_address) {
            state.connected_address = connected_address
        },
        setShowNoMetaMask(state, val){
            state.show_no_metamask = val;
        }
    },
    actions: {
        logout({commit}){
            commit('updateAddress', "")
        },
        async loadWeb3({
            commit, dispatch
        }) {
            commit('clearWallet');
            const web3 = new Web3(window.ethereum)
            const network = await web3.eth.getChainId();
            const accounts = await web3.eth.getAccounts();

            // await Moralis.start({
            //     serverUrl: process.env.VUE_APP_MORALIS_SERVER_URL,
            //     appId: process.env.VUE_APP_MORALIS_APP_ID
            //   });

            if (network === parseInt(process.env.VUE_APP_CHAIN) && accounts[0]) {
                const nativeBalance = await web3.eth.getBalance(accounts[0])
                commit('updateBalance', Math.round(web3.utils.fromWei(nativeBalance) * 1000000000) / 1000000000);
                dispatch('getWalletTokens', accounts[0])
            }

            commit('updateWeb3', web3);
            commit('updateNetwork', network);
            commit('updateAddress', accounts[0]);
            commit('setPageReady');
        },

        async requestNetworkChange({
            state
        }) {
            await state.web3.currentProvider.request({
                method: "wallet_switchEthereumChain",
                params: [{
                    chainId: process.env.VUE_APP_NETWORK
                }]
            })
        },

        async requestConnectAccount({
            state, commit
        }) {
            if(! window.ethereum){
                commit('setShowNoMetaMask', true)
            }else {
                await state.web3.currentProvider.request({
                    method: "eth_requestAccounts",
                })
            }
        },

       
    }
}