<template>
  <modal :open="open" @close="close">
    <div class="rounded-xl" v-if="ready" style="height: 700px">
      <div class="grid  grid-cols-1 md:grid-cols-2   p-6">
        <div>
          <token-header :token="token" />
        </div>
        <div class="md:text-right">
        <p class="text-xl text-gray-50">{{ token.formatted_price }}</p>
        <p  class="text-xl text-gray-50">Market Cap: {{ token.market_cap }}</p>
        </div>
      </div>
      <div class="relative" style="height: 600px">
        <TVChartContainer
          v-if="token"
          :token="token.token_address"
          :interval="interval"
        ></TVChartContainer>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "../Modal.vue";
import TokenChartMarketCap from "./TokenChartMarketCap.vue";
import TokenChartSocials from "./TokenChartSocials.vue";
import TokenChartName from "./TokenChartName.vue";
import TokenChartPrice from "./TokenChartPrice.vue";
import TVChartContainer from "./TVChartContainer.vue";
import TokenHeader from "../TokenHeader.vue";
import { formatStringWithCommas } from "@/utils/helpers";
const math = require('mathjs')
export default {
  name: "TokenChart",
  components: {
    Modal,
    TVChartContainer,
    TokenHeader,
    TokenChartMarketCap,
    TokenChartSocials,
    TokenChartPrice,
    TokenChartName,
  },
  data: () => ({
    interval: 15,
    ready: false,
  }),
  computed: {
    open() {
      return (
        this.$store.state.tokens.activeTokenChart &&
        this.$store.state.tokens.activeTokenChart.length > 0
      );
    },
    token() {
      return this.$store.getters.activeChartToken;
    },
   
  },
  methods: {
    close() {
      this.ready = false;
      this.$store.commit("setActiveTokenChart", false);
    },
    getToken() {
      console.log("get token");
    },
  },
  watch: {
    open(newVal) {
      if (newVal) {
        if (this.token) {
          this.ready = true;
        } else {
          this.getToken();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
