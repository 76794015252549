<template>
  <div class="mt-6 relative flex-1 px-4 sm:px-6">
    <!-- Replace with your content -->
    <card-tabs />
    <div class="flex flex-col items-center pt-4">
      <div class="mb-4">
        <primary-button @click="openSearch"
          ><SearchIcon class="h-5 w-5 mr-2" />Import Token</primary-button
        >
      </div>
      <mobile-card
        v-for="item in cards"
        :key="item.ca"
        :token_address="item.ca"
        :order="item.order"
      />
    </div>
    <!-- /End replace -->
  </div>
</template>

<script>
import CardTabs from "./CardTabs.vue";
import MobileCard from "./MobileCard.vue";
import PrimaryButton from './PrimaryButton.vue';
import { XIcon, SearchIcon } from "@heroicons/vue/outline";
export default {
  name: "TokenListSlideOver",
  components:{
      CardTabs, MobileCard, PrimaryButton,  XIcon, SearchIcon
  },
  computed: {
    cards() {
      return this.$store.getters.getActiveList;
    },
  },
  methods:{
      openSearch(){
          this.$emit('search')
      }
  }
};
</script>

<style scoped></style>
