<template>
  <aside class="flex space-between flex-col h-full sidebar ">
    <div class="flex items-center flex-col flex-none px-8 pt-6 pb-4">
      <img src="img/logo.png" class="logo mx-auto " />
       <!-- <h1 class="text-2xl text-white font-display mt-1" style="font-size: 30px">Swap</h1> -->
        <p class="text-base mt-6 text-typography text-center font-medium" style="font-size:14 px">TOP UP YOUR SHIRYO</p>
    </div>
    <div class="flex-grow pl-4 pr-4">    
      <ul>
      <li
          class="
            text-typography
            flex
            items-center
            uppercase
            tracking-wide
            font-medium
          "
          style="padding: 15px 0px; font-size: 12px"
        >
         <connect-wallet>
            <current-balance />
        </connect-wallet>
        </li>
        <li class="   
          mt-4
          text-typography
            flex
            items-center
            uppercase
            tracking-wide
            font-medium mb-1">Previous Transactions</li>
        <li
        v-for="(transaction, index) in $store.getters.previousTransactions"
        :key="index"
          class="
            text-typography
            flex
            items-center
            uppercase
            tracking-wide
            font-medium
            cursor-pointer
            hover:bg-black-light
            py-4
          "
        >
          <a :href="'https://etherscan.io/tx/'+transaction" target="_blank" class="flex  items-center ">
            <ChevronRightIcon class="h-5 w-5 " />&nbsp;{{ transaction.substring(0, 12) }}...
          </a>
        </li>
     <!--    <li
          class="
            text-typography
            flex
            items-center
            uppercase
            tracking-wide
            font-medium
            cursor-pointer
            hover:bg-black-light
          "
          style="padding: 16px; font-size: 12px"
        >
          <a href="" target="_blank" class="flex">
            <ChartBarIcon class="h-5 w-5 mr-2" />&nbsp;Chart
          </a>
        </li>
        <li
          class="
            text-typography
            flex
            items-center
            uppercase
            tracking-wide
            font-medium
            cursor-pointer
            hover:bg-black-light
          "
          style="padding: 16px; font-size: 12px"
        >
          <a href="" target="_blank" class="flex"> 
            <ExternalLinkIcon class="h-5 w-5 mr-2" />&nbsp;Launch dApp
          </a>
        </li>
           <li
          class="
            text-typography
            flex
            items-center
            uppercase
            tracking-wide
            font-medium
            cursor-pointer
            hover:bg-black-light
          "
          style="padding: 16px; font-size: 12px"
        >
          <a href="" target="_blank" class="flex">
            <UsersIcon class="h-5 w-5 mr-2" />&nbsp;Team
          </a>
        </li> -->
      </ul>
    </div>
    <div class="flex-none text-center px-6 relative">
        <img
      src="/img/wolf.png"
      class="wolf z-10 "
    />
      <!-- <img
        src="https://app.shiryoinu.com/favicon.png"
        class="mx-auto"
        style="margin-bottom: 24px"
      />
      <p class="text-typography">CURRENT PRICE</p>
      <p class="text-typography-light">
        ${{ $store.state.eth.shiryo_balance }}
      </p>
      <ul
        class="text-primary flex justify-between mx-auto"
        style="
          padding-top: 24px;
          padding-bottom: 24px;
          width: 105px;
          font-size: 24px;
        "
      >
        <li>
          <font-awesome-icon :icon="['fab', 'twitter']" />
        </li>
        <li>
          <font-awesome-icon :icon="['fab', 'discord']" />
        </li>
        <li>
          <font-awesome-icon :icon="['fab', 'telegram']" />
        </li>
      </ul> -->
    </div>
  </aside>
</template>

<script>
import CurrentBalance from "./CurrentBalance.vue";
import {
  ChevronLeftIcon,
  UsersIcon,
  ChartBarIcon,
  DocumentTextIcon,
  ExternalLinkIcon,
  ChevronRightIcon
} from "@heroicons/vue/outline";
import ConnectWallet from './ConnectWallet.vue';
export default {
  name: "Sidebar",
  components: {
    ChevronLeftIcon,
    UsersIcon,
    ChartBarIcon,
    DocumentTextIcon,
    ExternalLinkIcon,
    ConnectWallet,
    CurrentBalance,
    ChevronRightIcon
  },
};
</script>

<style scoped>
.wolf {

  /* transform: scaleX(-1); */
}

</style>
