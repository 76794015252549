import axios from 'axios';
import {
    ETH_TOKEN,
    ETH_COIN_ADDRESS
} from '../utils/ethereumFunctions';
import {
    getTokenDecimals, getTokenName, getTokenSymbol
} from '../utils/helpers';
const math = require("mathjs");
export default {
    state() {
        return {
            tokens: {},
            activeTokenChart: false,
            activeTokenInfo: false,
            showTokenSearchModal: false
        }
    },
    getters: {
        tokenByCa: state => (ca) => {
            if (!ca) return false;
            if (state.tokens[ca.toLowerCase()]) return state.tokens[ca.toLowerCase()];
            return false;
        },
        activeChartToken(state) {
            if (state.tokens[state.activeTokenChart]) return state.tokens[state.activeTokenChart];
            return false;
        },
        activeInfoToken(state) {
            if (state.tokens[state.activeTokenInfo]) return state.tokens[state.activeTokenInfo];
            return false;
        }
    },
    mutations: {
        addToken(state, token) {
            state.tokens[token.token_address.toLowerCase()] = token;
        },
        setActiveTokenInfo(state, token) {
            state.activeTokenInfo = token;
        },
        setActiveTokenChart(state, token) {
            state.activeTokenChart = token;
        },
        setShowTokenSearchModal(state, val) {
            state.showTokenSearchModal = val;
        }
    },
    actions: {
        async getTokenByCa({
            commit,
            rootState
        }, ca) {
            const address = (ca === ETH_COIN_ADDRESS) ? "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2" : ca;
            try {
                const req = await axios.get('https://api.coingecko.com/api/v3/coins/ethereum/contract/' + address);
                const decimals = await getTokenDecimals(address, rootState.connected_address);
                const price = math.format(req.data.market_data.current_price.usd, {
                    notation: "fixed",
                })
                const marketcap =  math.format(req.data.market_data.market_cap.usd, {
                    notation: "fixed",
                })
                
                const volume = math.format(req.data.market_data.total_volume.usd, {
                    notation: "fixed",
                })
                const token = {
                    found: true,
                    image: (ca === ETH_COIN_ADDRESS) ? ETH_TOKEN.image : req.data.image.large,
                    name: (ca === ETH_COIN_ADDRESS) ? ETH_TOKEN.name : req.data.name ,
                    symbol:  (ca === ETH_COIN_ADDRESS) ? ETH_TOKEN.symbol:  req.data.symbol,
                    token_address: ca,
                    price,
                    formatted_price: "$" + price,
                    decimals: decimals,
                    market_cap: new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        currencyDisplay: 'narrowSymbol'
                    }).format(marketcap),
                    links: req.data.links,
                    description: req.data.description.en,
                    daily_volume: new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        currencyDisplay: 'narrowSymbol'
                    }).format(volume),
                    circulating_supply: 0,
                    price_change_24h_percent: req.data.market_data.price_change_percentage_24h,
                    price_change_7d_percent: req.data.market_data.price_change_percentage_7d,
                    price_change_14d_percent: req.data.market_data.price_change_percentage_14d,
                    price_change_30d_percent: req.data.market_data.price_change_percentage_30d,
                    ath: math.format(req.data.market_data.ath.usd, {
                        notation: "fixed",
                    }),
                    atl: math.format(req.data.market_data.atl.usd, {
                        notation: "fixed",
                    })
                }
                if(token.market_cap==="$0.00"){
                    try {
                        const req2 = await axios.get(process.env.VUE_APP_API_ENDPOINT + "/cmc/quotes?token_address=" + address + "&key="+process.env.VUE_APP_API_KEY);
                        token.market_cap = new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            currencyDisplay: 'narrowSymbol'
                        }).format(req2.data.quote.USD.fully_diluted_market_cap)
                    }catch(err){
                        console.error(err)
                    }
                }
                if(ca.toLowerCase()==="0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2".toLowerCase()){
                    token.image = "https://svgshare.com/i/Bff.svg";
                }
                if(ca==="0x1e2f15302b90edde696593607b6bd444b64e8f02"){
                    token.image = "/img/wolf.png";
                }
                commit('addToken', token);
                return true;
            } catch (err) {
            
                try {
                    const name = await getTokenName(ca, rootState.connected_address);
                    const symbol = await getTokenSymbol(ca, rootState.connected_address);
                    const decimals = await getTokenDecimals(ca, rootState.connected_address);
                    // Will throw error if token not found
                    commit('addToken', {
                        found: true,
                        image: 'https://eu.ui-avatars.com/api/?background=1e2e87&color=fff&name=' + encodeURIComponent(name),
                        name,
                        symbol, 
                        token_address:ca,
                        price: 0, 
                        formatted_price: "$0.00",
                        decimals,
                        market_cap: "$0.00",
                        links:[],
                        description:"",
                        daily_volume: "$0.00",
                        circulating_supply: 0,
                        price_change_24h_percent:0,
                        price_change_7d_percent:0,
                        price_change_14d_percent:0,
                        price_change_30d_percent:0,
                        ath: "$0.00",
                        atl:"$0.00",
                        error: err.message
                    });
                }catch(err){
                    // Will throw error if token not found
                    commit('addToken', {
                        found: false,
                        token_address:ca,
                        error: err.message
                    });
                }
             
                return false;
            }
        }
    }
}