import Web3 from "web3";
import {
    swapExactEthForTokens,
    swapExactTokensForEth,
    swapExactTokensForTokens,
    swapTokensForExactTokens,
    swapTokensForExactEth,
    swapEthForExactTokens
} from '@/utils/uniswap';
import {
    Token,
    WETH,
    Fetcher,
    Trade,
    Route,
    TokenAmount,
    TradeType,
    Percent,
    ChainId,
    Pair
} from "@uniswap/sdk";
import {toRaw} from 'vue'
import {ETH_COIN_ADDRESS, getTokenAllowance} from '@/utils/ethereumFunctions';
import Moralis from 'moralis';
import { send, getTradeFunctionestimateGas } from "../utils/uniswap";
const math = require("mathjs");

export default {
    state() {
        return {
            // Token In 
            token_in_amount_raw: "0",  // Raw wei value
            //token_in_address: "0xc1a85faa09c7f7247899f155439c5488b43e8429",
            token_in_address: "",

        // Token in allowance
            token_in_allowance: false,
            token_in_allowance_loading: false,

            // Token Out
            token_out_amount_raw: "0",
            token_out_address: "",

            // Trade Data
            trade: false,
            slippage: 13,
            trade_type: 'EXACT_INPUT',
            getting_trade: false,
            gas_estimate: 0,
            gas_price: 0,
            getting_gas_estimate: false,
            estimateGasError: "",

            // Handles the popups
            swapSuccess: "",
            swapError: "",
            currencySelector :""
        }
    },
    getters: {
        tokenInDisplayAmount(state, getters) {
            // Formatted value (raw wei is in store)
            if(! getters.tokenIn) return ""; 
            const Moralisv1 = require("moralis-v1");
            return Moralisv1.Units.FromWei(state.token_in_amount_raw, getters.tokenIn.decimals)
        },
        tokenOutDisplayAmount(state, getters){
            // Formatted value (raw wei is in store)
            if(! getters.tokenOut) return ""; 
            const Moralisv1 = require("moralis-v1");
            return Moralisv1.Units.FromWei(state.token_out_amount_raw, getters.tokenOut.decimals)
        },
        tokenIn(state, getters){
            if(! state.token_in_address) return false;
            const token = getters.tokenByCa(state.token_in_address);
            return token;
        },
        tokenOut(state, getters){
            if(! state.token_out_address) return false;
            const token = getters.tokenByCa(state.token_out_address);
            return token;
        },
        priceImpact(state){
            if(! state.trade ) return "-";
            return (state.trade.priceImpact.toSignificant(6) - 0.3).toFixed(2);
        },
        minAmountOut(state, getters){
            if(! state.trade) return "-";
            return state.trade
            .minimumAmountOut(getters.slippageTolerance)
            .toSignificant(6)
        },
        maxAmountOut(state){
            if(! state.trade ) return "-";
            return state.trade.outputAmount.toSignificant(6)
        },
        minAmountIn(state){
            if(! state.trade) return "-";
            return state.trade.inputAmount.toSignificant(6)
        },
        maxAmountIn(state, getters){
            if(! state.trade) return "-";
            return state.trade
            .maximumAmountIn(getters.slippageTolerance)
            .toSignificant(6)
        },
        slippageTolerance(state){
            if(! state.trade) return false;
            return new Percent(state.slippage * 100, "10000");
        },
        gas(state){
            if(! state.gas_estimate || ! state.gas_price) return 0;
            const Moralisv1 = require("moralis-v1");
            return Moralisv1.Units.FromWei( math.multiply(state.gas_estimate, state.gas_price) );
        },
        executionPrice(state){
            if(! state.trade) return "-";
            return state.trade.executionPrice.toSignificant(6)
        }
    },
    mutations: {
        setCurrencySelector(state, val){
            state.currencySelector = val;
        },
        setTokenIn(state, address) {
            state.trade = false;
            state.token_in_amount = "";
            state.trade= false;
            state.gas_price  = 0;
            state.gas_estimate = 0;
            state.token_in_address = address
            state.estimateGasError = ""
        },
        setTokenOut(state, address) {
            state.trade = false; // clear quote on token change
            state.token_out_amount = "";
            state.trade= false;
            state.gas_price  = 0;
            state.gas_estimate = 0;
            state.token_out_address = address
            state.estimateGasError = ""
        },
        setTokenInLoading(state, val) {
            state.token_in_loading = val;
        },
        setTokenInAmount(state, amount) {
            state.estimateGasError = ""
            state.trade= false;
            state.token_in_amount = amount;
        },
        setTrade(state, trade) {
            state.trade = trade;
        },
        setTokenOutLoading(state, val){
            state.token_out_loading = val;
        },
        setTokenOutAmount(state, amount){
            state.estimateGasError = ""
            state.trade= false;
            state.token_out_amount = amount;
        },
       setGettingTrade(state, val){
           state.getting_trade = val;
        },
        setSlippage(state, val){
            state.estimateGasError = ""
            state.trade= false;
            state.slippage= val;
        },
        setAllowance(state, val){
            state.token_in_allowance = val;
        },
        setGettingGasEstimate(state, val){
            state.getting_gas_estimate = val;
        },
        setGasEstimate(state, val){
            state.gas_estimate = val;
        },
        setGasPrice(state, val){
            state.gas_price = val
        },
        setSwapError(state, val){
            state.swapError = val;
        },
        setTradeType(state, val){
            state.trade_type = val;
        },
        setSuccess(state, transactionRef){
            state.swapSuccess = transactionRef;
        },
        setEstimateError(state, error){
            state.estimateGasError = error;
        }
    },
    actions: {
        async setTokenIn({
            commit,
            rootState
        }, token_address) {
            if (!token_address) {
                commit('setTokenIn', false);
                return;
            }
            commit('setTokenIn', token_address)
        },
        async setTokenOut({
            commit,
            rootState
        }, token_address) {
            if (!token_address) {
                commit('setTokenOut', false);
                return;
            }
            commit('setTokenOut', token_address)
        },

        async getTrade({commit, state, getters, rootState}){
            console.log(state);
            commit('setGettingTrade', true)
            const _token_in = getters.tokenByCa(state.token_in_address);
            const _token_out = getters.tokenByCa(state.token_out_address);

            const token_in = 
                (state.token_in_address===ETH_COIN_ADDRESS) 
                ? WETH[ChainId.MAINNET] 
                : new Token(
                    ChainId.MAINNET,
                    state.token_in_address,
                    _token_in.decimals,
                  );
            const token_out = 
                  (state.token_out_address===ETH_COIN_ADDRESS) 
                  ? WETH[ChainId.MAINNET] 
                  : new Token(
                      ChainId.MAINNET,
                      state.token_out_address,
                      _token_out.decimals,
                    );

            let route;
            if( state.token_in_address===ETH_COIN_ADDRESS ) {
                const pair0 = await Fetcher.fetchPairData(token_in,  token_out);
                route = new Route([pair0], WETH[ChainId.MAINNET]);    
            }
            else if ( state.token_out_address===ETH_COIN_ADDRESS ) {
                const pair1 = await Fetcher.fetchPairData( token_in , token_out);
                route = new Route([pair1], token_in);    
            }
             else {
                const pair2 = await Fetcher.fetchPairData(token_in,  WETH[ChainId.MAINNET] );
                const pair3 = await Fetcher.fetchPairData(WETH[ChainId.MAINNET],  token_out );
                route = new Route([pair2,  pair3], token_in);
            }
            const Moralisv1 = require("moralis-v1");
            const amount = 
            (state.trade_type==='EXACT_INPUT') 
            ? Moralisv1.Units.Token(state.token_in_amount, _token_in.decimals).toString() 
            : Moralisv1.Units.Token(state.token_out_amount, _token_out.decimals).toString();
        
            const token = (state.trade_type==='EXACT_INPUT') ? token_in : token_out;
            const trade = new Trade(
                route,
                new TokenAmount(token, amount),
                (state.trade_type==='EXACT_INPUT') ? TradeType.EXACT_INPUT : TradeType.EXACT_OUTPUT
              );
           
             commit('setTrade', trade);

             if(state.token_in_address!==ETH_COIN_ADDRESS){
                const allowance = await getTokenAllowance(
                    state.token_in_address,
                    rootState.eth.connected_address
                  );
                  commit('setAllowance', Number(allowance) );
             }

             commit('setGettingTrade', false);
        },
        async estimateGasAction({dispatch, rootState, state, commit, getters}){
            commit('setGettingGasEstimate', true)
            const func = await dispatch('getTradeFunction');
            let args = {
                from: rootState.eth.connected_address,
            };
            if( state.token_in_address===ETH_COIN_ADDRESS ){
                args.value =  Number(state.trade.inputAmount.raw)
            }

            const {gas, error, message} = await getTradeFunctionestimateGas(func, args);               
            if(error){
                if(state.trade_type==='EXACT_OUTPUT'){
                    // If an exact output is specified but gas estimate fail its because the input token has a fee so we need to re run
                    // and manually set the amount in from the quote;
                    const Moralisv1 = require("moralis-v1");
                    const _token_in = getters.tokenByCa(state.token_in_address);
                    commit('setTradeType', 'EXACT_INPUT');
                    commit('setTokenInAmount', Moralisv1.Units.FromWei( toRaw(state.trade.inputAmount.raw).toString(), _token_in.decimals ));
                    commit('setTokenOutAmount', "");
                    await dispatch('getTrade');
                    await dispatch('estimateGasAction');
                    return;
                }

                commit('setEstimateError', message);
                commit('setGettingGasEstimate', false)
            }
            else {
                const web3 = new Web3(process.env.VUE_APP_PROVIDER);
                const gasPrice = await web3.eth.getGasPrice();
    
                commit('setGasEstimate', gas);
                commit('setGasPrice', gasPrice);
                commit('setGettingGasEstimate', false)
            }
        },
        async sendTrade({dispatch, rootState,state, commit}){
            const func = await dispatch('getTradeFunction');
            const web3 = new Web3(process.env.VUE_APP_PROVIDER);
            const gasPrice = await web3.eth.getGasPrice();
            let args = {
                from: rootState.eth.connected_address,
                gas: state.gas_estimate,
                gasPrice,
            };
            if( state.token_in_address===ETH_COIN_ADDRESS ){
                args.value =  Number(state.trade.inputAmount.raw)
            }
            const transaction = await send(func, args);
            console.log(transaction)
            commit('setSuccess', transaction.transactionRef)
        },
        async getTradeFunction({state, rootState, dispatch, getters, commit}){
            const token_in_address = (state.token_in_address===ETH_COIN_ADDRESS)  ? WETH[ChainId.MAINNET].address : state.token_in_address;
            const token_out_address = (state.token_out_address===ETH_COIN_ADDRESS)  ? WETH[ChainId.MAINNET].address : state.token_out_address;

            const path = (
                token_in_address !== WETH[ChainId.MAINNET].address && 
                token_out_address !== WETH[ChainId.MAINNET].address 
                ) ?
                [token_in_address,WETH[ChainId.MAINNET].address , token_out_address]
                :[token_in_address, token_out_address]

            const deadline = Math.floor(Date.now() / 1000) + 60 * 20;
            const to = rootState.eth.connected_address;

            try {
                if(state.trade_type==='EXACT_INPUT'){
                    return await dispatch('getExactInputTrade', {path, to, deadline});
                } else {
                    return await dispatch('getExactOutputTrade', {path, to, deadline});
                }
            }catch(err){
                commit('setGettingTrade', false);
                commit('setGettingGasEstimate', false)
                console.log(err);
            }
        },
        async getExactInputTrade({state, getters}, {path, to, deadline}){
            let func;
            const amountOutMin = state.trade.minimumAmountOut(getters.slippageTolerance).raw.toString();
            const amountIn = toRaw(state.trade.inputAmount.raw).toString();
            // 957600514303.8441
            // 957600514303844155392
            // 957600514303.8441

            if(state.token_in_address===ETH_COIN_ADDRESS)  {
                // If swapping eth we need to send it with the transaction as msg.value
                const value =  toRaw(state.trade.inputAmount.raw).toString();
                func = await swapExactEthForTokens(amountOutMin, path, to, deadline, value);
            }
            else if (state.token_out_address===ETH_COIN_ADDRESS)  {
                func = await swapExactTokensForEth(amountIn, amountOutMin, path, to, deadline);
            }
            else {
                func = await swapExactTokensForTokens(amountIn, amountOutMin, path, to, deadline);
            }
            return func;
        },
        async getExactOutputTrade({state, getters}, {path, to, deadline}){
            let func;
            const amountInMax = state.trade.maximumAmountIn(getters.slippageTolerance).raw.toString();
            const amountOut = toRaw(state.trade.outputAmount.raw).toString();

            if (state.token_in_address===ETH_COIN_ADDRESS)  {
                func = await swapEthForExactTokens(amountOut, path, to, deadline);
            }
            else if (state.token_out_address===ETH_COIN_ADDRESS)  {
                func = await swapTokensForExactEth(amountOut, amountInMax, path, to, deadline);
            }
            else {
                func = await swapTokensForExactTokens(amountOut, amountInMax, path, to, deadline);
            }
            return func;
        }
    }
}