<template>
  <div class="relative flex h-72 w-48 justify-end pt-1">
    <div
      class="
        h-64
        w-44
        flex
        items-center
        justify-center
        rounded-lg
        z-50
        border border-gray-500
      "
      :style="deckStyle"
    >
      <div>
        <!-- <button
          type="button"
          class="
            inline-flex
            items-center
            px-2
            py-1
            border border-transparent
            rounded-md
            shadow-sm
            text-xs
            font-medium
            text-white
            bg-indigo-600
            hover:bg-indigo-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            absolute
            deck-btn
          "
        >
          Manage Deck
        </button> -->
      </div>
      <img :src="deck.logo" class="w-12" />
    </div>
    <div
      v-for="i in 3"
      class="rounded-lg h-64 w-44 deck-card absolute border border-gray-500"
      :style="deckStyle"
    >
      &nbsp;
    </div>
  </div>
  <div class="flex justify-between text-white px-4">
    <ChevronLeftIcon class="h-6 w-6" />
    <SearchIcon class="h-6 w-6" />
    <PlusCircleIcon class="h-6 w-6" />
    <ChevronRightIcon class="h-6 w-6" />
  </div>
</template>

<script>
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  SearchIcon,
  PlusCircleIcon,
} from "@heroicons/vue/outline";
export default {
  name: "CardDeck",
  components: { ChevronLeftIcon, ChevronRightIcon, SearchIcon, PlusCircleIcon },
  props: {
    deck: Object,
  },
  computed: {
    deckStyle() {
      return {
        background: this.deck.cardColor,
      };
    },
  },
};
</script>

<style scoped>
.deck-card:nth-child(2) {
  transform: translate(-3px, 3px);
  z-index: 30;
}
.deck-card:nth-child(3) {
  transform: translate(-6px, 6px);
  z-index: 20;
}
.deck-card:nth-child(4) {
  transform: translate(-9px, 9px);
  z-index: 10;
}
.deck-btn {
    right: 40px;
    bottom: 45px;
}
</style>
