<template>
  <Container
    drop-class="hide"
    group-name="cards"
    behaviour="drop-zone"
    @drop="onDrop"
    :onDragEnter="onDragEnter"
    :onDragLeave="onDragLeave"
  >
    <AmountsCard
      heading="Amount In"
      :allowmax="true"
      context="in"
      :address="$store.state.swap.token_in_address"
      :hovered="hovered"
      :balance="balance"
      :symbol="symbol"
      :amount="$store.state.swap.token_in_amount"
      @updateValue="updateValue"
      @removeCard="removeCard"
    />
  </Container>
</template>

<script>
import { Container } from "vue3-smooth-dnd";
import AmountsCard from "./AmountsCard.vue";
import {ETH_COIN_ADDRESS} from '@/utils/ethereumFunctions'
export default {
  components: { AmountsCard, Container },
  name: "AmountsIn",
  computed: {
    balance() {
      return this.$store.getters.tokenBalance(
        this.$store.state.swap.token_in_address
      );
    },
    symbol() {
      return this.$store.getters.tokenByCa(
        this.$store.state.swap.token_in_address
      ).symbol;
    },
  },
  data() {
    return {
      hovered: false,
    };
  },
  mounted(){
    // default in address
    if(!  this.$store.state.swap.token_in_address){
     this.$store.dispatch("addStartCard", {
          ca:ETH_COIN_ADDRESS,
          position: 'in'
        });
    }
  },
  methods: {
    onDrop(dropItem) {
      this.hovered = false;
      if (dropItem.addedIndex === null && dropItem.removedIndex == null) return;

      if (
        dropItem.removedIndex === null &&
        this.$store.state.swap.token_in_address
      ) {
        // This catches when the card is replaced and adds the old card back to the sort order
        this.removeCard();
      }

      if (typeof dropItem.addedIndex !== undefined) {
        this.$store.dispatch("addCardToStaging", {
          ca:dropItem.payload.ca,
          position: 'in'
        });
      } else if (typeof dropItem.removedIndex !== undefined) {
        //this.removeCard();
      }
    },
    removeCard() {
      this.$store.dispatch("removeCardFromStaging", {
          ca: this.$store.state.swap.token_in_address,
          position: 'in',
      });
    },
    onDragEnter() {
      this.hovered = true;
    },
    onDragLeave() {
      this.hovered = false;
    },
    updateValue(val) {
      if (val === "max") {
        this.$store.commit("setTokenInAmount", this.balance);
      } else {
        this.$store.commit("setTokenInAmount", val);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
