import axios from 'axios';
import moment from 'moment-timezone'
import reduce from 'lodash/reduce'
const configurationData = {
    supported_resolutions: ['1', '5', '15', '30', '60', '1D', '1W', '1M']
};
import last from 'lodash/last'
const math = require("mathjs");
export default (tokenAddress) => ({
    onReady: (callback) => {
        setTimeout(() => callback(configurationData));
    },
    resolveSymbol: async (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
        if(tokenAddress==='eth'){
            tokenAddress = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
        }
        const response = await axios.get(
            process.env.VUE_APP_API_ENDPOINT + `/bitquery/resolveSymbol?key=${process.env.VUE_APP_API_KEY}&tokenAddress=${tokenAddress}`
        );

        if (!response.data.ethereum) {
            console.log(response.data);
            onResolveErrorCallback();
            return;
        }

        const coin = response.data.ethereum.dexTrades[0].baseCurrency;

        if (!coin) {
            onResolveErrorCallback();
        } else {

            const _getOptimalDecimals = (t) => {
                const e = ((Math.floor(t) || "") + "").length;
                let n = e ? 8 - e : -Math.floor(Math.log(t) / Math.log(10) + 1);
                return n = e ? n : n ? Math.max(n + 2 + 1, 8) : 8,
                Math.max(0, Math.min(n, 14))
            }

            const e = Math.pow(10, Math.max(
                    _getOptimalDecimals(null), 
                    _getOptimalDecimals(null)
                )
            );

            const symbol = {
                ticker: symbolName,
                name: `${coin.symbol}/USD`,
                session: '24x7',
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                pricescale: e,
                minmov: 1,
                minmove2: 0,
                has_empty_bars: !0,
                has_daily: !1,
                has_weekly_and_monthly: !1,
                has_intraday: !0,
                intraday_multipliers: ["1", "15"],
                supported_resolutions: configurationData.supported_resolutions,
                type: 'crypto',
                //data_status: 'streaming',
            }
            onSymbolResolvedCallback(symbol)
        }
    },
    getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
        try {
            const response = await axios.get(
                process.env.VUE_APP_API_ENDPOINT +
                '/bitquery/getBars?key='+process.env.VUE_APP_API_KEY+'&tokenAddress=' + tokenAddress +
                '&resolution=' + resolution +
                '&from=' + periodParams.from +
                '&to=' + periodParams.to +
                '&countback=' + periodParams.countBack +
                '&firstQuery=' + periodParams.firstDataRequest
            );
            const baseSymbol = symbolInfo.full_name.split('/')[0];
            // Group the results by timestamp
            const sortResults = reduce(response.data.ethereum.dexTrades, (result, el, index) => {
                const key = moment(el.timeInterval.minute).format()
                if(el.buyCurrency.symbol === "WETH" && el.sellCurrency.symbol === "USDC"){
                    // Quote
                    const quote = el;
                    const old = (result && result[key]) ? result[key] : {};
                    result[key] = {
                        ...{quote},
                        ...old
                    };
                }
                if(el.buyCurrency.symbol === baseSymbol && el.sellCurrency.symbol === "WETH"){
                    // base
                    const base = el;
                    const old = (result && result[key]) ? result[key] : {};
                    result[key] = {
                        ...{base},
                        ...old
                    };
                }
                if(baseSymbol==='WETH'){
                    result[key] = {
                        quote:el,
                        base:el
                    };
                }
                return result;
            }, {});

            const bars = reduce(sortResults, (result, item, index) => {
                const usdPrice = item.quote.median_price;
                // prev_close_price
                if(result.length===0 && ! item.base){
                    return result;
                }
                result.push({
                    open: (result.length===0) ? math.multiply(item.base.close, usdPrice) : result[result.length - 1].close,
                    close: (item.base) ? math.multiply(item.base.close, usdPrice) : result[result.length - 1].close,
                    high: (item.base) ? math.multiply(item.base.high, usdPrice) : result[result.length - 1].high,
                    low:  (item.base) ? math.multiply(item.base.low, usdPrice) : result[result.length - 1].low,
                    time:  moment(item.quote.timeInterval.minute).valueOf(),
                })
                return result;
            }, []);
            
// 0.000000009694741334626699
// 0.00000001157541791491043

            // console.log(bars);
            // console.log("End....")
            if (bars.length) {
                onHistoryCallback(bars, {
                    noData: false
                });
            } else {
                onHistoryCallback(bars, {
                    noData: true
                });
            }

        } catch (err) {
            console.error(err)
        }
    }
})