<template>
<button
    type="button"
    class="
      inline-flex
      items-center
      px-4
      py-3
      border border-transparent
      w-full
      justify-center
      shadow-sm
      text-base
      font-medium
      text-white
      bg-primary
    "
  >
<slot/>
  </button>
</template>

<script>
    export default {
        name:'PrimaryButton'
    }
</script>

<style scoped>

</style>