<template>
  <div class="grid grid-cols-1 lg:grid-cols-3 relative gap-x-4 gap-y-4">
    <div class="relative">
       <div
        @click="swap"
        class="
          absolute
          swap-icon
          z-20
          bg-black-light
          p-4
          text-gray-400
          rounded-full
          cursor-pointer
          transform
          hover:-translate-y-1 hover:scale-105
          transition
          duration-300
          ease-in-out
        "
      >
        <RefreshIcon class="h-7 w-7" />
      </div>
      <amounts-in />
    </div>
    <div class="">
      <amounts-out />
    </div>
    <div class="mb-12 lg:mb-0">
      <swap-confirm />
    </div>
  </div>
</template>

<script>
import {
  MenuAlt4Icon,
  ArrowRightIcon,
  RefreshIcon,
} from "@heroicons/vue/outline";
import AmountsIn from "./amountsCards/AmountsIn.vue";
import AmountsOut from "./amountsCards/AmountsOut.vue";
import SwapConfirm from "./SwapConfirm.vue";

export default {
  name: "SwapContainer",
  components: {
    AmountsIn,
    AmountsOut,
    SwapConfirm,
    RefreshIcon,
  },
  methods: {
    swap() {
      const tokenIn = this.$store.state.swap.token_in_address;
      this.$store.commit(
        "setTokenIn",
        this.$store.state.swap.token_out_address
      );
      this.$store.commit("setTokenOut", tokenIn);
    },
  },
};
</script>

<style scoped>
.swap-icon {
  left: 140px;
  bottom: -38px;
}
@media (min-width: 1024px) { 
  .swap-icon {
    left:auto;
    bottom: auto;
  top: 220px;
  right: -38px;
}
}
</style>
