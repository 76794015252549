<template>
  <div v-if="!token">
    <div class="h-72 w-52 mx-2">
      <ContentLoader
        viewBox="0 0 208 288"
        :speed="2"
        primaryColor="#131a37"
        secondaryColor="#313854"
      >
        <rect x="0" y="0" rx="10" ry="10" width="208" height="288" />
      </ContentLoader>
    </div>
  </div>
  <div v-if="token && !token.message" @click="selectCard">
    <card-body :token="token">
      <button
       @click="selectCard"
        type="button"
        class="
          inline-flex
          items-center
          px-4          
          py-1.5
          border border-transparent
          text-xs
          font-medium
          rounded
          shadow-sm
          text-white
          bg-primary
          focus:outline-none
        "
      >
        SELECT
      </button>
    </card-body>
  </div>
</template>

<script>
import CardBody from "./CardBody.vue";
import PrimaryButton from "./PrimaryButton.vue";
export default {
  name: "MobileCard",
  props: {
    token_address: {},
  },
  mounted() {
    if (!this.token) {
      this.$store.dispatch("getTokenByCa", this.token_address);
    }
  },
  computed: {
    token() {
      return this.$store.getters.tokenByCa(this.token_address);
    },
  },
  components: { CardBody, PrimaryButton },
  methods: {
    selectCard() {
      this.$store.dispatch("addCardToStaging", {
        ca: this.token_address,
        position: this.$store.state.swap.currencySelector,
      });
      this.$store.commit("setCurrencySelector", "");
    },
  },
};
</script>

<style scoped></style>
