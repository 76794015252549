<!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<template>
  <div class="">
    <div class="lg:hidden">
      <label for="tabs" class="sr-only">Select a tab</label>
      <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
      <select
        id="tabs"
        name="tabs"
        class="
          block
          w-full
          focus:ring-indigo-500 focus:border-indigo-500
          border-gray-300
          rounded-md
        "
        @change="(e)=>selectTab(e.target)"
      >
        <option v-for="tab in tabs" :key="tab.name" :selected="tab.current" :value="tab.value" >
          {{ tab.name }}
        </option>
      </select>
    </div>
    <div class="hidden lg:block">
      <nav class="flex space-x-4 tabs" aria-label="Tabs">
        <a
          v-for="tab in tabs"
          :key="tab.name"
          @click="selectTab(tab)"
          :class="[
            tab.current
              ? 'bg-primary text-white'
              : 'text-gray-50 hover:text-white btn-not-active',
            'hover:border-primary border-2 border-transparent uppercase px-4 py-3 font-medium text-sm rounded-md cursor-pointer items-center text-center flex',
          ]"
          :aria-current="tab.current ? 'page' : undefined"
        >
          {{ tab.name }}
        </a>
        <a
          @click="scrollLeft"
          class="
          hidden
          lg:flex
       items-center
            text-gray-50
            hover:border-primary
            border-2 border-transparent
            btn-not-active
            uppercase
            px-4
            py-3
            font-medium
            text-sm
            rounded-md
            cursor-pointer
          "
        >
          <ChevronLeftIcon class="h-5 w-5" />
        </a>
        <a
          @click="scrollRight"
          class="
          hidden
          lg:flex
            text-gray-50
            hover:border-primary
            border-2 border-transparent
            btn-not-active
            uppercase
            px-4
            py-3
            font-medium
            text-sm
            rounded-md
            cursor-pointer
            items-center
          "
        >
          <ChevronRightIcon class="w-5 h-5" />
        </a>
      </nav>
    </div>
  </div>
</template>

<script>
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/outline";

export default {
  name: "CardTabs",
  components: { ChevronLeftIcon, ChevronRightIcon },
  computed: {
    selected(){
      return this.$store.state.cards.activeList;
    },
    tabs() {
      return [
        { name: "Recent", value: "recent", current:  (this.selected==='recent') },
        { name: "Your Wallet", value: "wallet", current:  (this.selected==='wallet') },
        { name: "Trending", value: "trending", current:  (this.selected==='trending') },
        { name: "Common", value: "common", current: (this.selected==='common') },
      ];
    },
  },
  methods: {
    selectTab(tab) {

      this.$store.commit('setActiveList', tab.value)
    },
    scrollLeft() {
      const scrollTarget = document.getElementById("bottom-container");
      let i = 10;
      const step = 15;

      const interval = setInterval(() => {
        if (i > 100) {
          clearInterval(interval);
          return;
        }
        scrollTarget.scrollLeft -= step;
        i += 10;
      }, 10);
    },
    scrollRight() {
      const scrollTarget = document.getElementById("bottom-container");
      let i = 10;
      const step = 15;
      const interval = setInterval(() => {
        if (i > 100) {
          clearInterval(interval);
          return;
        }
        scrollTarget.scrollLeft += step;
        i += 10;
      }, 10);
    },
  },
  watch: {
    selected(newVal){
      if(newVal==="trending"&&!this.$store.state.cards.gotTrending){
        this.$store.dispatch('getTrendingTokens');
      }
    }
  }
};
</script>
<style scoped>
.btn-not-active {
  background: #0f152a;
}
.tabs {
}
</style>
