<template>
  <div
    class="
      mt-4
      bg-black-light
      rounded-lg
      w-full
      h-64
      flex
      items-center
      justify-center
    "
  >
    <div v-if="hovered" class="w-56 h-56 mx-auto absolute z-10 flex items-center flex-col justify-center bg-primary bg-opacity-20  border-dotted border-2 border-primary rounded-lg  ">
      <p class="text-white">Drop Here</p>
    </div>
    <div v-else-if="address" class="w-56 h-56 mx-auto absolute z-10 flex items-center flex-col justify-center">
      <AmountsCardDropped :token_address="address" @removeCard="$emit('removeCard')"/>
    </div>
    <div v-else >
      <div class="w-64 h-56 hidden lg:flex flex-col" >
           <div class="flex justify-between ">
        <ChevronLeftIcon
          class="w-12 h-12 transform rotate-45 text-black-lighter"
        />
        <ChevronRightIcon
          class="w-12 h-12 transform -rotate-45 text-black-lighter"
        />
      </div>
      <div class="py-2  flex-grow justify-center flex items-center">
        <p  class="uppercase text-white text-center opacity-80 tracking-wider ">
          Drop Currency Here
        </p>
      </div>
      <div class="flex justify-between">
        <ChevronLeftIcon
          class="w-12 h-12 transform -rotate-45 text-black-lighter"
        />
        <ChevronRightIcon
          class="w-12 h-12 transform rotate-45 text-black-lighter"
        />
      </div>
      </div>
      <div class="w-64 h-56 flex flex-col lg:hidden" @click="openCurrencySelector" >
           <div class="flex justify-between ">
        <ChevronLeftIcon
          class="w-12 h-12 transform rotate-45 text-black-lighter"
        />
        <ChevronRightIcon
          class="w-12 h-12 transform -rotate-45 text-black-lighter"
        />
      </div>
      <div class="py-2   flex-grow justify-center flex items-center">
        <p  class="uppercase text-white text-center opacity-80 tracking-wider lg:hidden">
          Tap to choose currency
        </p>
      </div>
      <div class="flex justify-between">
        <ChevronLeftIcon
          class="w-12 h-12 transform -rotate-45 text-black-lighter"
        />
        <ChevronRightIcon
          class="w-12 h-12 transform rotate-45 text-black-lighter"
        />
      </div>
      </div>
   
    </div>
  </div>
</template>

<script>
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/solid";
import AmountsCardDropped from './AmountsCardDropped.vue'

export default {
  name: "AmountsDropArea",
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
    AmountsCardDropped,
  },
  props: {
    address: {},
    hovered:{},
    context: {}
  },
  methods:{
    openCurrencySelector(){
      this.$store.commit('setCurrencySelector', this.context);
    }
  }
};
</script>

<style lang="scss" scoped></style>
