<template>
    <div>
        <div class="TVChartContainer" id="tv_chart_container"/>
    </div>
</template>

<script>
import api from '../../utils/api'
export default {
    name: 'TVChartContainer',
    props: {
        symbol: {
            default: 'SHIRYO',
            type: String,
        },
        interval: {
            default: 1,
            type: Number,
        },
        containerId: {
            default: 'tv_chart_container',
            type: String,
        },
        datafeedUrl: {
            default: 'https://demo-feed-data.tradingview.com',
            type: String,
        },
        libraryPath: {
            default: '/charting_library/',
            type: String,
        },
        chartsStorageUrl: {
            default: 'https://saveload.tradingview.com',
            type: String,
        },
        chartsStorageApiVersion: {
            default: '1.2',
            type: String,
        },
        clientId: {
            default: 'tradingview.com',
            type: String,
        },
        fullscreen: {
            default: false,
            type: Boolean,
        },
        autosize: {
            default: true,
            type: Boolean,
        },
        studiesOverrides: {
            type: Object,
        }
    },
    tvWidget: null,
    data() {
        return {
            addressInfo: {},
            searchValue: "",
            getSearchAddress: [],
            getAllAddressValue: [],
            showSearchChild: false,
        }
    },
    mounted() {
        const widgetOptions = {
            symbol: this.$store.state.tokens.activeTokenChart,
             debug: true,
            datafeed: api( this.$store.state.tokens.activeTokenChart),
            interval: 15,
            container_id: this.containerId,
            library_path: this.libraryPath,
            theme: 'Dark',
            locale: 'en',
            disabled_features: ['use_localstorage_for_settings', 'header_symbol_search', 'header_compare', 'header_saveload'],
            charts_storage_url: this.chartsStorageUrl,
            charts_storage_api_version: this.chartsStorageApiVersion,
            client_id: this.clientId,
            user_id: this.userId,
            fullscreen: this.fullscreen,
            autosize: this.autosize,
            studies_overrides: this.studiesOverrides,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        this.tvWidget = new TradingView.widget(widgetOptions)
    },
}
</script>

<style>
.TVChartContainer {
    position: absolute;
    width: 100%;
    height: 100%;
}
</style>