<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div>
    <span></span>
    <TransitionRoot as="template" :show="open">
      <Dialog
        as="div"
        class="fixed inset-0 overflow-hidden z-40"
        @close="close"
      >
        <div class="absolute inset-0 overflow-hidden">
          <TransitionChild
            as="template"
            enter="ease-in-out duration-500"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in-out duration-500"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay
              class="
                absolute
                inset-0
                bg-gray-500 bg-opacity-75
                transition-opacity
              "
            />
          </TransitionChild>
          <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <div class="relative w-screen max-w-xs">
                <TransitionChild
                  as="template"
                  enter="ease-in-out duration-500"
                  enter-from="opacity-0"
                  enter-to="opacity-100"
                  leave="ease-in-out duration-500"
                  leave-from="opacity-100"
                  leave-to="opacity-0"
                >
                  <div
                    class="
                      absolute
                      top-0
                      left-0
                      -ml-8
                      pt-4
                      pr-2
                      flex
                      sm:-ml-10 sm:pr-4
                    "
                  >
                    <button
                      type="button"
                      class="
                        rounded-md
                        text-gray-300
                        hover:text-white
                        focus:outline-none focus:ring-2 focus:ring-white
                      "
                      @click="close"
                    >
                      <span class="sr-only">Close panel</span>
                      <XIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </TransitionChild>
                <div
                  class="
                    h-full
                    flex flex-col
                    py-6
                    bg-black-light
                    shadow-xl
                    overflow-y-scroll
                  "
                >
                  <div class="px-4 sm:px-6" v-if="context === 'list'">
                    <DialogTitle class="text-lg font-medium text-gray-50">
                      Tap on a card to select
                    </DialogTitle>
                  </div>
                  <div class="px-4 sm:px-6 pb-8" v-else>
                    <div class="pr-40">
                      <primary-button @click="closeSearch"
                        ><ChevronLeftIcon
                          class="h-4 w-4 mr-2"
                        />Back</primary-button
                      >
                    </div>
                  </div>

                  <Transition name="fade">
                    <token-list-slide-over
                      v-if="context === 'list'"
                      @search="openSearch"
                    />
                  </Transition>
                  <Transition name="fade">
                    <token-search-slide-over
                      v-if="context === 'search'"
                      @close="closeSearch"
                    />
                  </Transition>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import { ref } from "vue";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XIcon, SearchIcon, ChevronLeftIcon } from "@heroicons/vue/outline";
import TokenListSlideOver from "./TokenListSlideOver.vue";
import TokenSearchSlideOver from "./TokenSearchSlideOver.vue";
import PrimaryButton from "./PrimaryButton.vue";
export default {
  name: "TokenSelectSlideOver",
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    TokenListSlideOver,
    TokenSearchSlideOver,
    PrimaryButton,
    ChevronLeftIcon,
  },
  computed: {
    open() {
      return this.$store.state.swap.currencySelector.length > 0;
    },
  },
  data() {
    return {
      context: "list",
    };
  },
  methods: {
    close() {
      this.$store.commit("setCurrencySelector", "");
    },
    importToken() {
      this.$store.commit("setShowTokenSearchModal", true);
    },
    openSearch() {
      this.context = "search";
    },
    closeSearch() {
      this.context = "list";
    },
  },
};
</script>
