
import Web3 from 'web3';
export const UNISWAP_ROUTER = "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D";

const getRouter = async ()=>{
    const web3 = new Web3(window.ethereum);
    return await new web3.eth.Contract(require("../abis/UniswapV2Router02.json").abi, UNISWAP_ROUTER);
}

export const getTradeFunctionestimateGas = async (func, args) => {
  const web3 = new Web3(process.env.VUE_APP_PROVIDER);
  try {
     args.gasPrice = await web3.eth.getGasPrice();
    const gas =  await func.estimateGas(args);
    return {
      gas, error:false
    }
  }catch(err){
    console.log(err)
    let message = "Unknown Error";
    if(err.message.includes('INSUFFICIENT_OUTPUT_AMOUNT')){
      message = "Transaction will fail, please increase slippage"
    }
    else if (err.message.includes('gas required exceeds allowance (0)')){
      message = "Not enough eth for gas";  
    }
    else {
      message = "Transaction will fail, please increase slippage"
    }
    return {
      error: true,
      message
    }
  }
};

export const send = async (func, args) => {
  const web3 = new Web3(process.env.VUE_APP_PROVIDER);
  args.gasPrice = await web3.eth.getGasPrice();
  return func.send(args)
};

// https://docs.uniswap.org/protocol/V2/reference/smart-contracts/router-02#swapexactethfortokens
export const swapExactEthForTokens = async (amountOutMin, path, to, deadline, value) => {
    const router = await getRouter();
    return router.methods.swapExactETHForTokens(
      amountOutMin, path, to, deadline
    );
};
  
// https://docs.uniswap.org/protocol/V2/reference/smart-contracts/router-02#swapexacttokensforeth
export const swapExactTokensForEth = async (amountIn, amountOutMin, path, to, deadline) => {
    const router = await getRouter();
    console.log(amountIn, amountOutMin, path, to, deadline)
    return router.methods.swapExactTokensForETHSupportingFeeOnTransferTokens(
      amountIn,  amountOutMin, path, to, deadline
    );
};

// https://docs.uniswap.org/protocol/V2/reference/smart-contracts/router-02#swapexacttokensfortokens
export const swapExactTokensForTokens = async (amountIn, amountOutMin, path, to, deadline) => {
    const router = await getRouter();
    return router.methods.swapExactTokensForTokensSupportingFeeOnTransferTokens(
        amountIn, amountOutMin, path, to, deadline
    );
};
  
// https://docs.uniswap.org/protocol/V2/reference/smart-contracts/router-02#swaptokensforexacttokens
export const swapTokensForExactTokens = async (amountOut, amountInMax, path, to, deadline) => {
    const router = await getRouter();
    console.log(amountOut, amountInMax, path, to, deadline);
    return router.methods.swapTokensForExactTokens(
      amountOut, amountInMax, path, to, deadline
    );
};
  
// https://docs.uniswap.org/protocol/V2/reference/smart-contracts/router-02#swaptokensforexacteth
export const swapTokensForExactEth = async (amountOut, amountInMax, path, to, deadline) => {
    const router = await getRouter();
    return router.methods.swapTokensForExactETH(
      amountOut, amountInMax, path, to, deadline
    );
};

// https://docs.uniswap.org/protocol/V2/reference/smart-contracts/router-02#swapethforexacttokens
export const swapEthForExactTokens = async (amountOut, path, to, deadline) => {
    const router = await getRouter();
    return router.methods.swapETHForExactTokens(
      amountOut, path, to, deadline
    );
};