<template>
  <div class="flex icons-section text-gray-400 relative">
     <a
      v-if="homepage"
      :href="homepage"
      target="_blank"
      class="select-none absolute"
      style="opacity: 0"
      ><font-awesome-icon :icon="'globe'"
            class="select-none"
    /></a>
   <a
      v-if="homepage"
      :href="homepage"
      target="_blank"
      class="select-none"
      ><font-awesome-icon :icon="'globe'"
            class="select-none"
    /></a>
     <a
      v-if="links.bitcointalk_thread_identifier"
      :href="links.bitcointalk_thread_identifier"
      target="_blank"
      class="noselect"
      ><font-awesome-icon :icon="['fab', 'bitcoin']"
            class="noselect"
    /></a>
    <a
      v-if="links.blockchain_site"
      :href="links.blockchain_site[0]"
      target="_blank"
      class="noselect"
      ><font-awesome-icon icon="eye"
            class="noselect"
    /></a>
    <a
      v-if="links.twitter_screen_name"
      :href="'https://twitter.com/' + links.twitter_screen_name"
      target="_blank"
      class="noselect"
      ><font-awesome-icon :icon="['fab', 'twitter']"
            class="noselect"
    /></a>
    <a
      v-if="facebookLink"
      :href="facebookLink"
      target="_blank"
      class="noselect"
      ><font-awesome-icon :icon="['fab', 'facebook-f']"
            class="noselect"
    /></a>
    <a
      v-if="mediumLink"
      :href="mediumLink"
      target="_blank"
      class="noselect"
      ><font-awesome-icon :icon="['fab', 'medium-m']"
            class="noselect"
    /></a>
    <a
      v-if="discordLink"
      :href="discordLink"
      target="_blank"
      class="noselect"
      ><font-awesome-icon :icon="['fab', 'discord']"
            class="noselect"
    /></a>

    <a
      v-if="links.subreddit_url"
      :href="links.subreddit_url"
      target="_blank"
      class="noselect"
      ><font-awesome-icon :icon="['fab', 'reddit-alien']"
            class="noselect"
    /></a>
    <a
      v-if="links.telegram_channel_identifier"
      :href="'https://t.me/'+ links.telegram_channel_identifier"
      target="_blank"
      class="noselect"
      ><font-awesome-icon :icon="['fab', 'telegram-plane']"
            class="noselect"
    /></a>
    <a  v-if="links.repos_url?.github?.length"  :href="links.repos_url?.github[0]" target="_blank" class="noselect"><font-awesome-icon :icon="['fab', 'github']"       class="noselect"/></a>
  </div>
</template>

<script>
import find from 'lodash/find';

export default {
  name: "TokenLinks",
  props: {
    links: {},
  },
  computed:{
    homepage(){
      return (this.links.homepage?.length) ? this.links.homepage[0] : false;
    },
    facebookLink(){
      const fb = find(this.links.official_forum_url, (link)=>{
        return (link.includes("facebook.com"))
      })
      return fb;
    },
    discordLink(){
      const fb = find(this.links.official_forum_url, (link)=>{
        return (link.includes("discord"))
      })
      return fb;
    },
    mediumLink(){
      const fb = find(this.links.official_forum_url, (link)=>{
        return (link.includes("medium.com"))
      })
      return fb;
    }
  }
};
</script>

<style scoped>
.icons-section {
  font-size: 21px;
}
.icons-section a {
  text-align: center;
  display: block;
  float: left;
  width: 32px;
  opacity: 0.6;
  margin: 0 3px;
  transition: 0.2s linear opacity;
}
.icons-section a:hover {
  opacity: 1;
}
</style>
