<template>
  <div class="flex">
    <div v-for="(card, index) in cards" :key="index" class="h-72 w-52 mx-2">
      <ContentLoader
        viewBox="0 0 208 288"
        :speed="2"
        primaryColor="#131a37"
        secondaryColor="#313854"
      >
        <rect x="0" y="0" rx="10" ry="10" width="208" height="288" />
      </ContentLoader>
    </div>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader";
export default {
  name: "LoadingCards",
  components: {
    ContentLoader,
  },
  data() {
    return {
      cards: 6,
    };
  },
};
</script>

<style scoped></style>
