<template>
  <li @click="select" class="flex justify-start items-center cursor-pointer hover:bg-black-lighter p-4 rounded-xl">
    <img :src="item.image" class="h-12 w-12 rounded-full"/>
    <p class="ml-6 text-lg text-white truncate text-left">
      {{ item.name }} ({{ item.symbol }})<br />
      {{ item.address }}
    </p>
  </li>
</template>

<script>

export default {
    name:"SearchResult",
    props:{
        item: Object
    },
    mounted(){
      this.$store.dispatch('getTokenByCa', this.item.address)
    },
    computed:{
    },
    methods:{
        select(){
            this.$emit('select', this.item.address);
        }
    }
};
</script>

<style scoped></style>
