<template>
  <div class="text-white px-6 py-3 text-base font-medium cursor-pointer hover:bg-black-light  float-right border border-primary text-center" @click="logout">
    <slot/><display-address :address="$store.state.eth.connected_address" />
    LOGOUT
  </div>
</template>

<script>
import DisplayAddress from "./DisplayAddress.vue";
import Moralis from "moralis";

export default {
  name: "CurrentBalance",
  components: {
    DisplayAddress,
  },
  computed: {
    balance() {
      const Moralisv1 = require("moralis-v1");
      return Moralisv1.Units.FromWei(
        this.$store.state.eth.eth_balance,
        "18"
      ).toString();
    },
  },
  methods:{
    logout(){
      this.$store.dispatch('logout')
    }
  }
};
</script>

<style scoped>
.truncate {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
