<template>
  <div class="h-full flex flex-col"  style="min-height: 368px">
    <div v-if="loading" class="text-white pt-8 ml-4">
      <loading-cards />
    </div>
    <div
      v-else-if="
        list === 'wallet' &&
        !$store.state.eth.connected_address
      "
      class="ml-4 mt-12 w-36"
    >
<primary-button @click="$store.dispatch('requestConnectAccount')">
        Connect Wallet
      </primary-button>
    </div>
    <div
      v-else-if="
        list === 'wallet' &&
        $store.state.eth.connected_network !== chainId
      "
            class="ml-4 mt-12 w-44"
    >
      <primary-button @click="$store.dispatch('requestNetworkChange')">
        Switch Network
      </primary-button>
    </div>
    <div v-else-if="!cards.length">
      <p class="text-white ml-4 mt-8 text-xl">No Cards</p>
    </div>
    <!-- column -->
    <Container
      v-else
      id="bottom-container"
      class="flex-grow overflow-y-auto overflow-x-hidden mt-6"
      orientation="horizontal"
      group-name="cards"
      :get-child-payload="getChildPayload"
      :drop-placeholder="{
        className: `bg-primary bg-opacity-20  
            border-dotted border-2 
            border-primary rounded-lg h-72 w-52 mx-4 mt-2 mb-4`,
        animationDuration: '200',
        showOnTop: true,
      }"
      :style="groupStyle"
      drag-class="bg-primary dark:bg-primary 
            border-2 border-primary-hover text-white 
            transition duration-100 ease-in z-50
            transform rotate-6 scale-110"
      drop-class="test"
      @drop="(e) => onCardDrop(e)"
    >
      <!-- Items -->
      <KanbanItem
        v-for="item in cards"
        :key="item.ca"
        :token_address="item.ca"
        :order="item.order"
      ></KanbanItem>
    </Container>
  </div>
</template>

<script>
import { Container, Draggable } from "vue3-smooth-dnd";
import { applyDrag } from "../utils/helpers";
import KanbanItem from "./Card.vue";
import AmountsCard from "./amountsCards/AmountsCard.vue";
import LoadingCards from "./LoadingCards.vue";
import PrimaryButton from '../components/PrimaryButton.vue'
export default {
  name: "CardContainer",
  props: {
    id: {},
  },
  components: { Container, AmountsCard, KanbanItem, LoadingCards, PrimaryButton },
  computed: {
    list(){
      return this.$store.state.cards.activeList;
    },
    cards() {
      return this.$store.getters.getActiveList;
    },
    loading() {
      if (
        this.$store.state.cards.activeList === "trending" &&
        !this.$store.state.cards.gotTrending
      ) {
        return true;
      }
      return false;
    },
    ready() {
      return this.$store.state.wallet.gotTokens;
    },
    groupStyle() {
      return {
        overflowX: "auto",
        // border: '1px solid #ccc'
      };
    },
  },
  mounted() {
    if (!this.$store.state.wallet.gotTokens) {
      //this.$store.dispatch("getWalletTokens");
    }
  },
  setup() {
    return {
      chainId: parseInt(process.env.VUE_APP_CHAIN),
    };
  },
  methods: {
    getChildPayload(index) {
      return this.cards[index];
    },
    onCardDrop(dropItem) {
      if (dropItem.addedIndex !== null) {
        this.$store.dispatch("moveCardInList", {
          addToPosition: dropItem.addedIndex + 1,
          movedFromPosition: dropItem.removedIndex + 1,
          ca: dropItem.payload.ca,
          list: this.$store.state.cards.activeList,
        });
      }
    },
    getCardPayload(columnId) {
      return (index) => {
        return this.scene.children.filter((p) => p.id === columnId)[0].children[
          index
        ];
      };
    },
  },
};
</script>
<style>
/** NB: dont remove, 
* When using orientation="horizontal" it auto sets "display: table"
* In this case we need flex and not display table  
*/
.smooth-dnd-container.horizontal {
  display: flex !important;
}
</style>
