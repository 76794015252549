<template>
  <div class="h-screen  xl:overflow-hidden main-content lg:pl-8 ">
    <board>
      <div class="swap-container max-w-7xl px-4 lg:px-0">
        <div class="xl:hidden ">
          <img
            src="img/logo.png"
            class="logo mx-auto max-w-xs px-12"
          />
    
          <p class="mt-6 text-typography text-xl text-center font-medium">
            TOP UP YOUR SHIRYO CURRENCY
          </p>
          <div class="my-6 max-w-xs mx-auto">
            <connect-wallet>
              <current-balance>
                Connected: 
              </current-balance>
            </connect-wallet>
          </div>
        </div>
        <swap-container />
      </div>
      <div class="hidden xl:flex bottom-container  ">
        <div class="bottom-container-cards w-full">
          <div class="max-w-7xl flex mt-8 items-center justify-between">
            <div class="flex items-center flex-grow">
              <h1
                class="text-2xl text-white font-display mr-8 flex-none"
                style="font-size: 30px"
              >
                TOKENS
              </h1>
              <import-token class="pr-12"/>
            </div>

            <div class="">
              <card-tabs />
            </div>
          </div>

          <card-container />
        </div>
      </div>
    </board>
  </div>
</template>

<script>
import Board from "./Board.vue";
import CardDeck from "./CardDeck.vue";
import CardContainer from "./CardContainer.vue";
import SwapContainer from "./SwapContainer.vue";
import CardTabs from "./CardTabs.vue";
import ImportToken from "./ImportToken";
import ConnectWallet from "./ConnectWallet.vue";
import CurrentBalance from "./CurrentBalance.vue";
export default {
  name: "MainContent",
  components: {
    Board,
    CardContainer,
    SwapContainer,
    CardTabs,
    ImportToken,
    ConnectWallet,
    CurrentBalance,
  },
  mounted() {
    //this.$store.dispatch('loadTrendingCards');
  },
};
</script>

<style scoped>
.bottom-container {
  width: calc(100vw - 250px);
}
</style>
