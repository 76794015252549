<template>
  <div class="board-wrapper relative ">
    <div class="relative z-30 xl:h-screen pt-8 flex items-center">
      <!-- <div class="max-w-7xl mx-auto sm:hidden">
        <h1 class="text-2xl text-white font-display" style="font-size: 30px">Shiryo Swap</h1>
        <p class="text-xl text-typography" style="font-size:14 px">TOP UP YOUR SHIRYO CURRENCY</p>
      </div> -->
      <div class=" ">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:"Board",
};
</script>

<style scoped>

</style>
