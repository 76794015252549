import {
    createStore
} from 'vuex'
import VuexPersistence from "vuex-persist";
import eth from './eth';
import cards from './cards';
import transactions from './transactions';
import swap from './swap';
import tokens from './tokens'
import wallet from './wallet'

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    reducer: (state) => ({
        cards: state.cards,
        swap:{
            token_in_address: state.swap.token_in_address,
            token_out_address: state.swap.token_out_address,
            slippage: state.swap.slippage
        }
    })
  })

const store = createStore({
    modules: {
        cards,
        eth,
        swap,
        transactions,
        tokens, 
        wallet
    },
    state:{
        pageReady: false
    },
    getters: {
        state(state){
            return state;
        }
    },
    mutations: {
        setPageReady: (state) => (state.pageReady = true),
    },
    actions: {},
    plugins: [vuexLocal.plugin],
});
export default store;