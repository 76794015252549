<template>
  <div class="w-full relative">
    <div v-if="error" class="bg-red-700 p-2">
      <p class="text-white">{{ error }}</p>
      <p @click="clearError" class="rounded-full text-white absolute -top-2 -right-2 cursor-pointer"><XCircleIcon class="h-7 w-7"/></p>
    </div>
    <primary-button v-else @click="approve" class="w-full">
      <font-awesome-icon
        v-if="loading"
        icon="spinner-third"
        class="mr-2"
        spin
      ></font-awesome-icon>
      {{ loading ? "Approving..." : "Approve Swap" }}
    </primary-button>
  </div>
</template>

<script>
import { BigNumber } from "@0x/utils";
import Web3 from "web3";
import PrimaryButton from "./PrimaryButton.vue";
import {XCircleIcon} from "@heroicons/vue/solid";
import {setAllowance} from "@/utils/ethereumFunctions"
import Moralis from 'moralis';

export default {
  name: "ApproveSwapButton",
  components: {
    PrimaryButton,XCircleIcon
  },
  data() {
    return {
      loading: false,
      error: "",
    };
  },
  computed:{
      token_in() {
      return this.$store.getters.tokenByCa(
        this.$store.state.swap.token_in_address
      );
    },
  },
  methods: {
    clearError() {
      this.error = "";
    },
    async approve() {
      this.loading = true;
      const Moralisv1 = require("moralis-v1");
      try {
        const amount  = Moralisv1.Units.Token(
           (this.$store.state.swap.trade_type === 'EXACT_INPUT') ? this.$store.state.swap.token_in_amount : '1000000000000000000',  
          this.token_in.decimals
        );
        const newAllowance = await setAllowance(
          this.$store.state.swap.token_in_address,
          this.$store.state.eth.connected_address,
          amount
        );
        debugger;
        this.$store.commit('setAllowance', newAllowance);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.error = error.message;
        console.log(error);
      }
    },
  },
};
</script>

<style scoped></style>
